export const purchases_columns = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "CLIENT",
    width: "25%",
    align: "left",
  },
  {
    name: "TOTAL",
    width: "15%",
    align: "left",
  },
  {
    name: "Issued Date",
    width: "15%",
    align: "left",
  },
  {
    name: "PAYMENT",
    width: "15%",
    align: "left",
  },
  {
    name: "ORDER STATUS",
    width: "15%",
    align: "center",
  },

  {
    name: "ACTION",
    width: "10%",
    align: "center",
  },
];
