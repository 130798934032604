export const player_columns = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "PLAYER",
    width: "25%",
    align: "left",
  },
  {
    name: "GENDER",
    width: "10%",
    align: "left",
  },
  {
    name: "DATE OF BIRTH",
    width: "20%",
    align: "left",
  },
  {
    name: "PROGRAM",
    width: "10%",
    align: "center",
  },
  {
    name: "LEVEL",
    width: "10%",
    align: "center",
  },
  {
    name: "BOOKING",
    width: "10%",
    align: "left",
  },
  {
    name: "ACTION",
    width: "10%",
    align: "left",
  },
];
