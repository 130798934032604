import React from "react";
import { Button, Typography } from "@mui/material";
import { colors } from "../../theme/Colors";
import xIcon from "../../imgs/xIcon.svg";
import pointsIcon from "../../imgs/3points-icon.svg";
import eyeIcon from "../../imgs/eye.svg";
import deleteWhite from "../../imgs/delete_white.svg";
import editGray from "../../imgs/edit_gray.svg";
function ButtonSquare({ number, action, ...props }) {
  switch (action) {
    //modify button

    case "close":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ width: "35px", height: "30px", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` }, textTransform: "none" }}>
          <img src={xIcon} alt="close-icon" />
        </Button>
      );
    case "3points":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ width: "35px", height: "30px", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` }, textTransform: "none" }}>
          <img src={pointsIcon} alt="modify-icon" />
        </Button>
      );
    case "edit_outlined":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ border: "2px solid #A8AAAE", width: "35px", height: "35px", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` }, textTransform: "none" }}>
          <img src={editGray} alt="modify-icon" />
        </Button>
      );
    case "show_outlined":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ border: "2px solid #A8AAAE", width: "35px", height: "35px", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` }, textTransform: "none" }}>
          <img src={eyeIcon} alt="modify-icon" />
        </Button>
      );
    case "show":
      return (
        <Button color="neutral" {...props} onClick={props.onClick} sx={{ width: "35px", height: "30px", borderRadius: "6px", "&:hover": { backgroundColor: `#f0f0f0` }, textTransform: "none" }}>
          <img src={eyeIcon} alt="modify-icon" />
        </Button>
      );
    case "number-active":
      return (
        <Button {...props} onClick={props.onClick} sx={{ display: `${number === "Next &raquo;" || number === "&laquo; Previous" ? "none" : "inherit"}`, height: "35px", width: "40px", borderRadius: "6px", padding: "0", backgroundColor: `${colors.main}`, "&:hover": { backgroundColor: colors.main } }}>
          <Typography color="white">{number}</Typography>
        </Button>
      );
    case "number":
      return (
        <Button
          {...props}
          onClick={props.onClick}
          sx={{ color: "#4B465C", display: `${number === "Next &raquo;" || number === "&laquo; Previous" ? "none" : "inherit"}`, height: "35px", width: "40px", borderRadius: "6px", padding: "0", backgroundColor: `rgba(75, 70, 92, 0.08)`, "&:hover": { color: "white", backgroundColor: colors.main } }}
        >
          <Typography>{number}</Typography>
        </Button>
      );
    case "Next":
      return (
        <Button {...props} onClick={props.onClick} sx={{ color: "#4B465C", padding: "0px 10px", borderRadius: "6px", backgroundColor: `rgba(75, 70, 92, 0.08)`, "&:hover": { color: "white", backgroundColor: colors.main } }}>
          <Typography fontSize="15px" fontWeight="500" lineHeight="18px">
            Next
          </Typography>
        </Button>
      );
    case "Previous":
      return (
        <Button {...props} onClick={props.onClick} sx={{ color: "#4B465C", padding: "0px 10px", borderRadius: "6px", backgroundColor: `rgba(75, 70, 92, 0.08)`, "&:hover": { color: "white", backgroundColor: colors.main } }}>
          <Typography fontSize="15px" fontWeight="500" lineHeight="18px">
            Previous
          </Typography>
        </Button>
      );
    case "delete_red":
      return (
        <Button {...props} onClick={props.onClick} sx={{ width: "35px", height: "35px", color: "#4B465C", padding: "0px 10px", borderRadius: "6px", backgroundColor: `#F41616`, "&:hover": { color: "white", backgroundColor: colors.main } }}>
          <img src={deleteWhite} alt="delete" />
        </Button>
      );
  }
}

export default ButtonSquare;
