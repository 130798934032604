import { Autocomplete, Box, Button, FormControl, IconButton, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { DateNavigator, DayView, Toolbar } from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState, EditingState, IntegratedEditing } from "@devexpress/dx-react-scheduler";
import { Scheduler, WeekView, MonthView, Appointments, AppointmentForm } from "@devexpress/dx-react-scheduler-material-ui";
import ContentBox from "../../components/ui/ContentBox";
import { modalStyle, modalStyleOverflow, modalStyleVertical } from "../../theme/ModalStyle";
import ButtonSquare from "../../components/ui/ButtonSquare";
import moment from "moment";
import PersonIcon from "@mui/icons-material/Person";
import Api from "../../api/Api";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Urls } from "../../api/Urls";
import { ToastContainer, toast } from "react-toastify";
import { CalendarPicker, DatePicker, DateTimePicker, LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import FormikErrorText from "../../components/ui/FormikErrorText";
import { PlusOne } from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate } from "react-router-dom";
import MySwitch from "../../components/ui/MySwitch/MySwitch";
import { colors } from "../../theme/Colors";
function Schedule({ id, showFilters, viewName, dateS }) {
  const [visible, setVisible] = useState(false);
  const [currentViewName, setCurrentViewName] = useState("Month");
  const [court, setCourt] = useState(null);
  const [program, setProgram] = useState("");
  const [openCancelSession, setOpenCancelSession] = useState({ open: false, id: null });
  const [programs, setPrograms] = useState([]);
  const [showFilter, setShowFilter] = useState(showFilters);
  const [courts, setCourts] = useState([]);
  const [dataCourts, setDataCourts] = useState([]);
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [edit, setEdit] = useState(false);

  const [dateUpdate, setDateUpdate] = useState(null);
  const [date, setDate] = useState(null);
  const [sessionType, setSessionType] = useState(false);
  const [change, setChange] = useState(false);
  const [selectedSessionUpdate, setSelectedSessionUpdate] = useState("");
  const [durations, setDurations] = useState([]);
  const [levels, setLevels] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [terms, setTerms] = useState([]);
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState("");
  const [duration, setDuration] = useState("");
  const [term, setTerm] = useState();
  const [dataT, setData] = useState({});
  const [coachFil, setCoachFil] = useState();
  const [schedule, setSchedule] = useState([]);
  const [dateSe, setDateS] = useState(dateS ? dateS : null);
  const [scheduleAdded, setScheduleAdded] = useState([]);
  const [addedScheduleToshow, setAddedScheduleToshow] = useState({
    open: false,
    data: null,
  });
  useEffect(() => {
    document.title = "BTA admin - Schedule";
  }, []);

  const [initialValuesNorml, setInitialValuesNorml] = useState({
    program: "",
    level: "",
    start_time: "",
    duration: "",
    coach: "",
  });
  const [initialValuesPrv, setInitialValuesPrv] = useState({
    program: "",
    level: "",
    start_time: "",
    duration: "",
    coach: "",
  });
  const handleClickCancel = (id) => {
    setOpenCancelSession({ open: true, id: id });
  };
  const handleCloseCancel = () => {
    setOpenCancelSession({ open: false, id: null });
  };
  const handleCloseUpdate = () => {
    setOpenDetails({
      open: false,
      data: null,
    });
    setVisible(false);
    setInitialValuesNorml({
      program: program,
      level: "",
      start_time: "",
      duration: "",
      coach: "",
    });
    setInitialValuesPrv({
      program: program,
      level: "",
      start_time: "",
      duration: "",
      coach: "",
    });
  };
  useEffect(() => {
    if (showFilters === false) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
    if (viewName === "Day") {
      setCurrentViewName("Day");
    }
  }, [showFilters]);
  const validationSchema = yup.object({
    program: yup?.object().shape({
      id: yup?.string().required("This field is required"),
    }),
    level: yup?.object().shape({
      id: yup?.string().required("This field is required"),
    }),
    start_time: yup?.string().required("this field is required"),
    duration: yup?.object().shape({
      duration: yup?.string().required("This field is required"),
    }),
    coach: yup?.string().required("this field is required"),
  });
  const validationSchemaPrivate = yup.object({
    start_time: yup?.string().required("this field is required"),
    duration: yup?.object().shape({
      duration: yup?.string().required("This field is required"),
    }),
    coach: yup?.string().required("this field is required"),
  });
  const [openDetails, setOpenDetails] = useState({
    open: false,
    data: null,
  });

  const ExternalViewSwitcher = ({ currentViewName }) => (
    <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="flex-end">
      <Box display="flex" flexDirection="row">
        {showFilter !== false && (
          <Button onClick={() => setCurrentViewName("Month")} variant={currentViewName === "Month" ? "views_active_left_radius" : "views_inactive_left_radius"}>
            Month
          </Button>
        )}
        {showFilter !== false && (
          <Button onClick={() => setCurrentViewName("Week")} variant={currentViewName === "Week" ? "views_active" : "views_inactive"}>
            Week
          </Button>
        )}
        {showFilter !== false && (
          <Button onClick={() => setCurrentViewName("Day")} variant={currentViewName === "Day" ? "views_active_right_radius" : "views_inactive_right_radius"}>
            Day
          </Button>
        )}
      </Box>
    </Box>
  );
  useEffect(() => {
    Api.get(Urls.GET_PRIVATE_LESSONS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setDurations(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, []);
  // handlers
  const handleClickAppointment = (data, edit) => {
    setEdit(edit);
    console.log(edit, "edit");
    setOpenDetails({
      open: true,
      data: data,
    });
    if (data?.session_type === "PROGRAM") {
      let start_time = moment(data?.start_time, "HH:mm:ss").format("hh:mm A");

      setDuration(data?.duration);
      setStartTime(start_time);

      setSelectedSessionUpdate(data?.session_type === "PROGRAM" ? "normal" : "private");
      setInitialValuesNorml({
        program: { id: data?.program_id, name: data?.program_name },
        start_time: new Date(data?.start_datetime),
        duration: { id: null, duration: data?.duration },
        coach: data?.coach_id,
        coach_first_name: data?.coach_first_name,
        coach_last_name: data?.coach_last_name,
        level: { id: data?.level_id, name: data?.level_name },
      });
      Api.get(Urls.GET_PRIVATE_LESSONS, {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          setDurations(res?.data?.data);
          setStartTime(data?.start_datetime);
          setInitialValuesPrv({
            start_time: new Date(data?.start_datetime),
            duration: { id: null, duration: "" },
            coach: "",
            coach_first_name: "",
            coach_last_name: "",
          });
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
    if (data?.session_type === "PRIVATE_LESSON") {
      setDateUpdate(data?.start_datetime);
      setDuration(data?.duration);
      setStartTime(data?.start_datetime);
      Api.get(Urls.GET_PRIVATE_LESSONS, {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          setDurations(res?.data?.data);
          setSelectedSessionUpdate("private");
          setInitialValuesPrv({
            start_time: new Date(data?.start_datetime),
            duration: { id: null, duration: data?.duration },
            coach: data?.coach_id,
            coach_first_name: data?.coach_first_name,
            coach_last_name: data?.coach_last_name,
          });
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };
  const handleClickAppointementPlus = (data) => {
    const array = scheduleAdded.filter((schedule) => schedule.startDate.split(" ")[0] === data?.startDate.split(" ")[0]);
    const array_2 = schedule.filter((schedule) => schedule.startDate.split(" ")[0] === data?.startDate.split(" ")[0] && !schedule?.new);
    const global_array = array_2.concat(array);
    setAddedScheduleToshow({
      open: true,
      data: global_array,
    });
  };
  //reorganize data in better shape to be used
  const limitOccurenceNumber = (data) => {
    let reorganizedArray = [];
    let addedArray = [];
    for (let index = 0; index < data.length; index++) {
      let count = 1;
      const element = data[index];
      reorganizedArray.push(element);
      for (let i = index + 1; i < data.length; i++) {
        const elementToCompare = data[i];
        if (element?.startDate.split(" ")[0] === elementToCompare?.startDate.split(" ")[0] && count < 2) {
          reorganizedArray.push(elementToCompare);
          count = count + 1;
          if (i === data?.length - 1) {
            data = data?.filter((da) => da?.startDate?.split(" ")[0] !== element?.startDate?.split(" ")[0]);
            data.unshift(null);
          }
        } else {
          if (count === 2 && element?.startDate.split(" ")[0] === elementToCompare?.startDate.split(" ")[0]) {
            reorganizedArray.push({ ...element, new: true, startDate: element?.startDate?.split(" ")[0] + " 23:59:00", endDate: element?.endDate?.split(" ")[0] + " 23:59:10" });
            count = count + 1;
          }
          if (count > 2 && element?.startDate.split(" ")[0] === elementToCompare?.startDate.split(" ")[0]) {
            addedArray.push(elementToCompare);
          }
          if (i === data?.length - 1) {
            data = data?.filter((da) => da?.startDate?.split(" ")[0] !== element?.startDate?.split(" ")[0]);
            data.unshift(null);
          }
        }
      }
    }
    console.log(reorganizedArray, "reor");
    setSchedule(reorganizedArray);
    setScheduleAdded(addedArray);
  };
  //get coachs filter
  const [users, setUsers] = useState([]);
  useEffect(() => {
    Api.get(Urls?.GET_COACHES, {
      headers: { "content-type": "application/json", Authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setUsers(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, []);
  //get global schedule
  useEffect(() => {
    console.log(term, "term useEffect");
    if (term) {
      setSchedule([]);
      console.log(selectedDate, "selected_date");
      const date = new Date(selectedDate);

      date.setDate(1);
      const firstDay = new Date(date + 1);

      date.setMonth(date.getMonth() + 1);
      date.setDate(1);
      const lastDay = new Date(date);
      console.log(firstDay, lastDay);
      Api.get(Urls?.GET_GLOBAL_SCHEDULE + term?.id + "/courtSchedules", {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          ...(court?.id ? { courtId: court?.id } : null),
          ...(sessionType && { sessionType: "PRIVATE_LESSON" }),
          ...(program?.id && !sessionType ? { programId: program?.id } : null),
          ...(coachFil?.id ? { coachId: coachFil?.id } : null),
          ...(selectedDate && showFilter ? { startDatetime: new Date(term?.start_date) } : { startDatetime: new Date(term?.start_date) }),
          ...(selectedDate && showFilter ? { endDatetime: new Date(term?.end_date) } : { endDatetime: new Date(term?.end_date) }),
        },
      })
        .then((res) => {
          let data = [];
          for (let index = 0; index < res?.data?.data?.length; index++) {
            const element = res?.data?.data[index];
            data.push({ ...element, startDate: element?.start_datetime, endDate: element?.end_datetime });
          }
          if (currentViewName === "Month") {
            console.log(data, "data");
            limitOccurenceNumber(data);
          } else {
            setSchedule(data);
          }
        })
        .catch((err) => {
          toast?.error(err?.response?.data?.message);
        });
    }
  }, [program, court, change, coachFil, currentViewName, term, sessionType]);
  useEffect(() => {
    console.log(term, "term", selectedDate);
    if (term) {
      setSelectedDate(new Date() > new Date(term?.start_date) && new Date() < new Date(term?.end_date) ? new Date() : new Date(term?.start_date));
    }
  }, [term]);

  //get list courts
  useEffect(() => {
    Api.get(Urls.GET_COURTS_LIST, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("courts list:", response.data.data);
        setCourts(response?.data?.data);
        setDataCourts(response?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);
  const [dataProg, setDataProg] = useState([]);
  //get list programs
  useEffect(() => {
    console.log(Urls.GET_PROGRAMS, "PROGRAMS");
    Api.get(Urls.GET_PROGRAMS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("programs list:", response.data.data);
        setPrograms(response?.data?.data);
        setDataProg(response?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);
  //get list terms
  useEffect(() => {
    Api.get(Urls.GET_TERMS_LIST, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("terms list:", response.data.data);
        setTerms(response?.data?.data);
        setData(response?.data);
        setTerm(response?.data?.data[0]);
        if (showFilters === false) {
          for (let index = 0; index < response?.data?.data.length; index++) {
            let Term = response?.data?.data[index];
            if (new Date() > new Date(Term?.start_date) && new Date() < new Date(Term?.end_date)) {
              setTerm(Term);
            }
          }
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);
  const [coachs, setCoachs] = useState([]);

  useEffect(() => {
    console.log("executed useEffect to get coachs");
    console.log(term, startTime, duration, dateUpdate, selectedSessionUpdate);
    if (term && startTime && selectedSessionUpdate !== "normal") {
      let startTime_ = new Date(startTime);
      startTime_ = moment(startTime_, "");
      let endTime = startTime_?.clone().add(duration?.duration ? duration?.duration : duration, "minutes");
      Api.get(Urls?.GET_AVAILABLE_COACHS, {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          startDatetime: moment(startTime_).format("YYYY-MM-DD HH:mm:00"),
          endDatetime: moment(endTime).format("YYYY-MM-DD HH:mm:00"),
        },
      })
        .then((res) => {
          console.log(res);
          let coachList = res?.data?.data?.filter((coach) => coach.id !== initialValuesPrv?.coach);
          setCoachs(coachList);
        })
        .catch((err) => {
          toast?.error(err?.response?.data?.message);
        });
    }
  }, [term, duration, startTime]);

  const CustomAppointmentContent = ({ data, ...restProps }) => {
    // Customize the appointment content based on data properties
    const customStyles = {
      background: data?.new ? "lightgray" : data?.program_color_start && data?.program_color_end ? `linear-gradient(-30deg, #${data?.program_color_start}, #${data?.program_color_end})` : "#1E213E",
      // color: data?.session_type === "PROGRAM" ? data?.color : "white",
      color: data?.new ? "white" : getContrastColor(data?.program_color_start),
      height: data?.new ? "70%" : "100%",
      width: data?.new ? "20px" : currentViewName !== "Week" && currentViewName !== "Day" ? "90%" : "100%",
      boxShadow: "0",
      ":hover": { filter: "brightness(0.95)" },
      filter: data?.new ? "brightness(1)" : data?.cancelled_at ? "brightness(0.6)" : "brightness(1)",
      cursor: "pointer",
    };

    const selectedSession = data?.session_type === "PROGRAM" ? false : true;
    return (
      <>
        {!data?.new && (
          <Box onClick={() => handleClickAppointment(data, selectedSession)} sx={customStyles} display="flex" justifyContent="center" alignItems="center">
            {/* Render appointment content here */}
            {data?.level_name && (
              <Box sx={{ zIndex: "99999" }} padding="2px 5px" backgroundColor="#D0CFCF" color={getContrastColor("#D0CFCF")} position="absolute" top="4px" right="4px" borderRadius="3px">
                <Typography fontSize="13px" fontWeight="600">
                  {data?.level_name}
                </Typography>
              </Box>
            )}
            <Typography textAlign="center" color="inherit" fontSize="13px" lineHeight="14px" fontWeight="500">
              {data?.program_name ? data?.program_name : "PRIVATE LESSON"}
            </Typography>
          </Box>
        )}
        {data?.new && (
          <Box sx={{ width: "90%" }} onClick={() => handleClickAppointementPlus(data)} display="flex" justifyContent="center" alignItems="center">
            <Box borderRadius="6px" marginTop="4px" sx={customStyles} display="flex" justifyContent="center">
              <MoreHorizIcon sx={{ fontSize: 15 }} />
            </Box>
          </Box>
        )}
      </>
    );
  };
  const submitUpdatePrivateCourt = (values) => {
    let startTime_ = new Date(startTime);
    startTime_ = moment(startTime_, "");
    Api.patch(
      Urls.UPDATE_PRIVATE_LESSON_GLOBAL + openDetails?.data?.id,
      {
        court_id: court?.id,
        term_id: term?.id,
        coach_id: values?.coach,
        ...(values?.duration?.id ? { private_lesson_id: values?.duration?.id } : null),
        start_time: moment(startTime_).format("YYYY-MM-DD HH:mm:00"),
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success("updated");
        handleCloseUpdate();
        setChange(!change);
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
      });
  };
  const submitCreatePrivateLesson = (values) => {
    let startTime_ = new Date(startTime);
    startTime_ = moment(startTime_, "");
    Api.post(
      Urls.REPLACE_PROGRAM_SCHEDULE,
      {
        court_id: court?.id,
        term_id: term?.id,
        coach_id: values?.coach,
        private_lesson_id: values?.duration?.id,
        start_datetime: moment(startTime_).format("YYYY-MM-DD HH:mm:00"),
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success("created");
        handleCloseUpdate();
        setVisible(false);
        setChange(!change);
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
      });
  };
  const handleCancelAppointement = (bool) => {
    Api.post(
      Urls.CANCEL_APPOINTEMENT + openDetails?.data?.id + "/cancel",
      {
        refund: bool ? 1 : 0,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success("Canceled");
        setChange(!change);
        handleCloseUpdate();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  //background color & text color management for schedules
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex?.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  };

  const getContrastColor = (backgroundColor) => {
    const calculateLuminance = (rgb) => {
      const [r, g, b] = rgb.map((value) => {
        value /= 255;
        return value <= 0.03928 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4);
      });

      return 0.2126 * r + 0.7152 * g + 0.0722 * b;
    };

    const rgb = hexToRgb(backgroundColor);
    const luminance = calculateLuminance(rgb);

    return luminance > 0.5 ? "black" : "white";
  };
  useEffect(() => {
    setInitialValuesPrv({
      program: program,
      level: "",
      start_time: new Date(date),
      duration: "",
      coach: "",
    });
    setStartTime(new Date(date));
  }, [date]);
  console.log(initialValuesPrv, "initial values prv");
  return (
    <Box>
      {showFilters === false ? (
        <ContentBox>
          <Box sx={{ overflowX: { xs: "scroll", sm: "scroll", md: "hidden", lg: "hidden" }, flexDirection: { xs: "column", sm: "column", md: "column", lg: "row" } }} display="flex" gap="26px">
            {showFilter && (
              <Box flex={1} display="flex" flexDirection="column" gap="12px">
                <Box>
                  <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                    Filter
                  </Typography>
                </Box>
                <Box>
                  <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                    Program
                  </Typography>
                  <Autocomplete
                    sx={{ minWidth: "200px" }}
                    options={programs}
                    disableClearable={true}
                    getOptionLabel={(e) => e.name}
                    isOptionEqualToValue={(option) => option?.id}
                    onChange={(event, value) => {
                      if (value !== null) {
                        //console.log("selecting facture state value", value);
                        //console.log("selecting facture state value", value.id);
                        setProgram(value?.id);
                        //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                      } else {
                        setProgram(null);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select type user" />}
                  />
                </Box>
                <Box>
                  <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                    Coach
                  </Typography>
                  <Autocomplete
                    sx={{ minWidth: "200px" }}
                    options={users}
                    getOptionLabel={(e) => e.first_name + " " + e.last_name}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    onChange={(event, value) => {
                      if (value !== null) {
                        //console.log("selecting facture state value", value);
                        //console.log("selecting facture state value", value.id);
                        setCoachFil(value);
                        //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                      } else {
                        setCoachFil(null);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select coach" />}
                  />
                </Box>
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CalendarPicker
                      date={selectedDate}
                      maxDate={term?.end_date}
                      minDate={term?.start_date}
                      onChange={(newValue) => {
                        setSelectedDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            )}

            <Box flex={4} borderLeft={showFilters === false ? "none" : "1px solid lightgray"}>
              <Scheduler data={schedule} height={700}>
                <ViewState onCurrentDateChange={!dateS ? (date) => (new Date(term?.end_date) > new Date(date) ? setSelectedDate(date) : null) : (date) => (new Date(date) <= new Date(term?.end_date) ? setDateS(date) : null)} currentDate={dateS ? dateSe : selectedDate} currentViewName={currentViewName} />
                <WeekView cellDuration={30} startDayHour={6} endDayHour={22} />
                <MonthView />
                <DayView cellDuration={30} startDayHour={6} endDayHour={22} />
                <Appointments appointmentComponent={CustomAppointmentContent} />

                <Toolbar />
                <Box marginLeft="20px" display="flex" alignItems="center" justifyContent="space-between">
                  <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                    TERM : {term?.name} {court?.name ? " / " : ""} {court?.name}{" "}
                  </Typography>
                  {showFilters === false && (
                    <Box>
                      <Button onClick={() => navigate("/admin/schedules")} variant="primary_active">
                        Go to schedule
                      </Button>
                    </Box>
                  )}
                </Box>
                <DateNavigator onCurrentDateChange={(date) => console.log(date)} />
                <ExternalViewSwitcher currentViewName={currentViewName} />
              </Scheduler>
            </Box>

            <Modal open={openDetails?.open} onClose={handleCloseUpdate}>
              <Box sx={modalStyleVertical}>
                <Box>
                  <Box marginBottom="26px" alignItems="center" display="flex" justifyContent="space-between">
                    <Box>
                      <Typography fontSize="18px" lineHeight="24px" fontWeight="500" color="#4B465C">
                        Schedule session for {moment(openDetails?.data?.start_datetime).format("dddd, MMMM DD, yyyy hh:mm A")}
                      </Typography>
                    </Box>
                    <ButtonSquare action="close" onClick={handleCloseUpdate} />
                  </Box>
                  <Box display="flex" gap="4px" marginBottom="24px">
                    <Box>
                      <Button variant={selectedSessionUpdate === "normal" ? "primary_active" : "primary_inactive"}>Schedule a session</Button>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => {
                          if (selectedSessionUpdate === "normal") {
                            setOpenDetails({
                              open: false,
                              data: null,
                            });
                            setVisible(true);
                            setDate(initialValuesNorml?.start_time);
                            setEdit(true);
                            setSelectedSessionUpdate("private");
                          }
                        }}
                        variant={selectedSessionUpdate !== "normal" ? "primary_active" : "primary_inactive"}
                      >
                        Schedule a private session
                      </Button>
                    </Box>
                  </Box>
                  {selectedSessionUpdate === "normal" && (
                    <Formik onSubmit={(values) => console.log(values)} validationSchema={validationSchema} initialValues={initialValuesNorml} enableReinitialize={true}>
                      {({ errors, setFieldValue, values }) => (
                        <Form>
                          <Box display="flex" flexDirection="column" gap="16px">
                            <Box>
                              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                Program
                              </Typography>
                              <Autocomplete
                                sx={{ minWidth: "300px" }}
                                options={[]}
                                value={values?.program}
                                disabled={!edit}
                                getOptionLabel={(e) => e.name}
                                isOptionEqualToValue={(option, value) => option?.id === value.id}
                                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select program" />}
                                onChange={(event, value) => {
                                  if (value !== null) {
                                    //console.log("selecting facture state value", value);
                                    //console.log("selecting facture state value", value.id);
                                    setFieldValue("program", value);
                                    setFieldValue("coach", null);
                                    setProgram(value);
                                    setFieldValue("duration", null);
                                    //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                  } else {
                                    setFieldValue("program", null);
                                  }
                                }}
                              />
                              <ErrorMessage component={FormikErrorText} name="program" />
                            </Box>

                            <Box>
                              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                Level
                              </Typography>
                              <Autocomplete
                                onChange={(event, value) => {
                                  if (value !== null) {
                                    //console.log("selecting facture state value", value);
                                    //console.log("selecting facture state value", value.id);
                                    setFieldValue("level", value);
                                    //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                  } else {
                                    setFieldValue("level", null);
                                  }
                                }}
                                sx={{ minWidth: "300px" }}
                                options={[]}
                                disabled={!edit}
                                value={values?.level ? values?.level : null}
                                getOptionLabel={(e) => e.name}
                                isOptionEqualToValue={(option, value) => option?.id === value.id}
                                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select level" />}
                              />
                              <ErrorMessage component={FormikErrorText} name="level" />
                            </Box>
                            <Box>
                              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                Start time
                              </Typography>
                              <Autocomplete
                                onChange={(event, value) => {
                                  if (value !== null) {
                                    //console.log("selecting facture state value", value);
                                    //console.log("selecting facture state value", value.id);
                                    setFieldValue("start_time", value);
                                    setStartTime(value);
                                    setFieldValue("coach", null);

                                    //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                  } else {
                                    setFieldValue("start_time", null);
                                    setStartTime(null);
                                    setFieldValue("coach", null);
                                  }
                                }}
                                sx={{ minWidth: "300px" }}
                                options={[]}
                                disabled={!edit}
                                value={moment(values?.start_time).format("DD MMMM YYYY hh:mm A")}
                                getOptionLabel={(e) => e}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select start time" />}
                              />
                              <ErrorMessage component={FormikErrorText} name="start_time" />
                            </Box>
                            <Box>
                              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                Duration
                              </Typography>
                              <Autocomplete
                                sx={{ minWidth: "300px" }}
                                options={[]}
                                disabled={!edit}
                                value={values?.duration ? values?.duration : null}
                                getOptionLabel={(e) => e?.duration?.toString()}
                                isOptionEqualToValue={(option, value) => option?.duration === value?.duration}
                                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select duration" />}
                                onChange={(event, value) => {
                                  if (value !== null) {
                                    setFieldValue("duration", value);
                                    setDuration(value);
                                    setFieldValue("coach", null);
                                  } else {
                                    setFieldValue("duration", null);
                                    setDuration(null);
                                  }
                                }}
                              />
                              <ErrorMessage component={FormikErrorText} name="duration" />
                            </Box>
                            <Box>
                              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                Coach
                              </Typography>
                              <FormControl sx={{ height: "42px" }} fullWidth>
                                <Select
                                  onChange={(e) => {
                                    setFieldValue("coach", e.target?.value);
                                    console.log(e.target?.value, "e log");
                                  }}
                                  disabled={!edit}
                                  value={values?.coach}
                                  placeholder="Select coach"
                                  sx={{ height: "42px", borderColor: "#DEDFE4" }}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                >
                                  <MenuItem key={values?.coach} value={values?.coach}>
                                    <Box display="flex" alignItems="center" gap="5px">
                                      <Box marginTop="5px">{values?.coach?.url_avatar ? <img src={values?.coach?.url_avatar} style={{ height: "32px", width: "32px", borderRadius: "50%" }} alt="proile-pic" /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Box>
                                      <Box>{values?.coach_first_name + " " + values?.coach_last_name}</Box>
                                    </Box>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              <ErrorMessage component={FormikErrorText} name="coach" />
                            </Box>
                            <Box display="flex" gap="16px" alignItems="center">
                              {!openDetails?.data?.cancelled_at && (
                                <Button onClick={() => handleClickCancel(openDetails?.data?.id)} variant="primary_active">
                                  Cancel
                                </Button>
                              )}
                              <Button onClick={handleCloseUpdate} variant="primary_inactive">
                                Close
                              </Button>
                              <Button variant="primary_active" onClick={() => navigate("/admin/players/schedule", { state: { data: openDetails?.data } })}>
                                Go to details
                              </Button>
                            </Box>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  )}
                  {selectedSessionUpdate !== "normal" && (
                    <Formik onSubmit={(values) => submitUpdatePrivateCourt(values)} validationSchema={validationSchemaPrivate} initialValues={initialValuesPrv} enableReinitialize={true}>
                      {({ errors, setFieldValue, values }) => (
                        <Form>
                          <Box display="flex" flexDirection="column" gap="16px">
                            <Box>
                              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                Start time
                              </Typography>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                  value={values.start_time}
                                  onChange={(value) => {
                                    setStartTime(new Date(value));
                                    setFieldValue("start_time", value);
                                    setFieldValue("coach", null);
                                    console.log(value);
                                  }}
                                  ampm={false}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </LocalizationProvider>
                              <ErrorMessage component={FormikErrorText} name="start_time" />
                            </Box>
                            <Box>
                              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                Duration
                              </Typography>
                              <Autocomplete
                                sx={{ minWidth: "300px" }}
                                options={durations}
                                disabled={!edit}
                                value={values?.duration ? values?.duration : null}
                                getOptionLabel={(e) => e?.duration?.toString()}
                                isOptionEqualToValue={(option, value) => option?.duration === value?.duration}
                                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select duration" />}
                                onChange={(event, value) => {
                                  if (value !== null) {
                                    setFieldValue("duration", value);
                                    setDuration(value);
                                    setFieldValue("coach", null);
                                  } else {
                                    setFieldValue("duration", null);
                                    setDuration(null);
                                  }
                                }}
                              />
                              <ErrorMessage component={FormikErrorText} name="duration" />
                            </Box>
                            <Box>
                              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                Coach
                              </Typography>
                              <FormControl sx={{ height: "42px" }} fullWidth>
                                <Select
                                  onChange={(e) => {
                                    setFieldValue("coach", e.target?.value);
                                    console.log(e.target?.value, "e log");
                                  }}
                                  value={values?.coach}
                                  disabled={!edit}
                                  placeholder="Select coach"
                                  sx={{ height: "42px", borderColor: "#DEDFE4" }}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                >
                                  {coachs?.map((coach) => (
                                    <MenuItem key={coach?.id} value={coach?.id}>
                                      <Box display="flex" alignItems="center" gap="5px">
                                        <Box marginTop="5px">{coach?.url_avatar ? <img src={coach?.url_avatar} style={{ height: "32px", width: "32px", borderRadius: "50%" }} alt="proile-pic" /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Box>
                                        <Box>{coach?.first_name + " " + coach?.last_name}</Box>
                                      </Box>
                                    </MenuItem>
                                  ))}
                                  <MenuItem key={values?.coach} value={values?.coach}>
                                    <Box display="flex" alignItems="center" gap="5px">
                                      <Box marginTop="5px">{values?.coach?.url_avatar ? <img src={values?.coach?.url_avatar} style={{ height: "32px", width: "32px", borderRadius: "50%" }} alt="proile-pic" /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Box>
                                      <Box>{values?.coach_first_name + " " + values?.coach_last_name}</Box>
                                    </Box>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              <ErrorMessage component={FormikErrorText} name="coach" />
                            </Box>
                            {edit && (
                              <Box display="flex" gap="16px" alignItems="center">
                                <Button type="submit" variant="primary_active">
                                  Update
                                </Button>

                                {!openDetails?.data?.cancelled_at && (
                                  <Button onClick={() => handleClickCancel(openDetails?.data?.id)} variant="primary_active">
                                    Cancel
                                  </Button>
                                )}
                                <Button onClick={handleCloseUpdate} variant="primary_inactive">
                                  Close
                                </Button>
                                <Button
                                  onClick={() => {
                                    setOpenDetails({
                                      open: false,
                                      data: null,
                                    });
                                    setVisible(true);
                                    setDate(initialValuesPrv?.start_time);
                                    setEdit(true);
                                    setSelectedSessionUpdate("private");
                                  }}
                                  variant="primary_active"
                                >
                                  Schedule a new private lesson
                                </Button>
                              </Box>
                            )}
                            <Button variant="primary_active" onClick={() => navigate("/admin/players/schedule", { state: { data: openDetails?.data } })}>
                              Go to details
                            </Button>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  )}
                </Box>
              </Box>
            </Modal>
            <Modal open={visible} onClose={() => setVisible(false)}>
              <Box sx={modalStyleVertical}>
                <Box>
                  <Box marginBottom="26px" alignItems="center" display="flex" justifyContent="space-between">
                    <Box>
                      <Typography fontSize="18px" lineHeight="24px" fontWeight="500" color="#4B465C">
                        Schedule session for {moment(date).format("dddd, MMMM DD, yyyy")}
                      </Typography>
                    </Box>
                    <ButtonSquare action="close" onClick={() => setVisible(false)} />
                  </Box>
                  <Formik onSubmit={(values) => submitCreatePrivateLesson(values)} validationSchema={validationSchemaPrivate} initialValues={initialValuesPrv} enableReinitialize={true}>
                    {({ errors, setFieldValue, values }) => (
                      <Form>
                        <Box display="flex" flexDirection="column" gap="16px">
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              Start time
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DateTimePicker
                                value={values.start_time}
                                onChange={(value) => {
                                  setStartTime(new Date(value));
                                  setFieldValue("start_time", value);
                                  setFieldValue("coach", null);
                                  console.log(value);
                                }}
                                ampm={false}
                                disableIgnoringDatePartForTimeValidation={true}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                            <ErrorMessage component={FormikErrorText} name="start_time" />
                          </Box>
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              Duration
                            </Typography>
                            <Autocomplete
                              sx={{ minWidth: "300px" }}
                              options={durations}
                              disabled={!edit}
                              value={values?.duration ? values?.duration : null}
                              getOptionLabel={(e) => e?.duration?.toString()}
                              isOptionEqualToValue={(option, value) => option?.duration === value?.duration}
                              renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select duration" />}
                              onChange={(event, value) => {
                                if (value !== null) {
                                  setFieldValue("duration", value);
                                  setDuration(value);
                                  setFieldValue("coach", null);
                                } else {
                                  setFieldValue("duration", null);
                                  setDuration(null);
                                }
                              }}
                            />
                            <ErrorMessage component={FormikErrorText} name="duration" />
                          </Box>
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              Coach
                            </Typography>
                            <FormControl sx={{ height: "42px" }} fullWidth>
                              <Select
                                onChange={(e) => {
                                  setFieldValue("coach", e.target?.value);
                                  console.log(e.target?.value, "e log");
                                }}
                                value={values?.coach}
                                disabled={!edit}
                                placeholder="Select coach"
                                sx={{ height: "42px", borderColor: "#DEDFE4" }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                              >
                                {coachs?.map((coach) => (
                                  <MenuItem key={coach?.id} value={coach?.id}>
                                    <Box display="flex" alignItems="center" gap="5px">
                                      <Box marginTop="5px">{coach?.url_avatar ? <img src={coach?.url_avatar} style={{ height: "32px", width: "32px", borderRadius: "50%" }} alt="proile-pic" /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Box>
                                      <Box>{coach?.first_name + " " + coach?.last_name}</Box>
                                    </Box>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <ErrorMessage component={FormikErrorText} name="coach" />
                          </Box>
                          <Box display="flex" gap="16px" alignItems="center">
                            <Button type="submit" variant="primary_active">
                              Add
                            </Button>

                            <Button onClick={handleCloseUpdate} variant="primary_inactive">
                              Close
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Modal>
          </Box>
        </ContentBox>
      ) : (
        <Box display="flex" flexDirection="column" gap="26px" alignItems="start">
          <ToastContainer />
          <Box>
            <Typography fontSize="22px" fontWeight="500" lineHeight="30px" color="#4B465C">
              Schedule
            </Typography>
          </Box>

          {term && showFilter && (
            <Box width="100%">
              <ContentBox>
                <Box width="100%" display="flex" gap="16px">
                  <Box flex={1}>
                    <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                      Select term
                    </Typography>
                    <Autocomplete
                      fullWidth
                      options={terms}
                      value={term}
                      disableClearable={true}
                      getOptionLabel={(e) => e.name}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      onChange={(event, value) => {
                        if (value !== null) {
                          //console.log("selecting facture state value", value);
                          //console.log("selecting facture state value", value.id);
                          setTerm(value);
                          //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                        } else {
                          setTerm(null);
                        }
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select term" />}
                    />
                  </Box>
                  <Box flex={1}>
                    <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                      Select court
                    </Typography>
                    <Autocomplete
                      fullWidth
                      options={courts}
                      value={court}
                      disableClearable={true}
                      getOptionLabel={(e) => e.name}
                      isOptionEqualToValue={(option, value) => option?.id === value.id}
                      onChange={(event, value) => {
                        if (value !== null) {
                          //console.log("selecting facture state value", value);
                          //console.log("selecting facture state value", value.id);
                          setCourt(value);
                          //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                        } else {
                          setCourt(null);
                        }
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select court" />}
                    />
                  </Box>
                </Box>
              </ContentBox>
            </Box>
          )}
          <ContentBox>
            <Box sx={{ overflowX: { xs: "scroll", sm: "scroll", md: "hidden", lg: "hidden" }, flexDirection: { xs: "column", sm: "column", md: "column", lg: "row" } }} display="flex" gap="26px">
              {showFilter && (
                <Box flex={1} display="flex" flexDirection="column" gap="12px">
                  <Box>
                    <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                      Filter
                    </Typography>
                  </Box>
                  <Box display="flex" gap="10px" flexDirection="row" alignItems="center">
                    <Typography fontSize="20px" fontWeight={600} color={colors.darkgray}>
                      PRIVATE LESSONS
                    </Typography>
                    <MySwitch
                      isOn={sessionType}
                      onChange={() => {
                        setSessionType(!sessionType);
                      }}
                    />
                  </Box>
                  {!sessionType && (
                    <Box>
                      <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                        Program
                      </Typography>
                      <Autocomplete
                        sx={{ minWidth: "200px" }}
                        options={programs}
                        getOptionLabel={(e) => e.name}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        onChange={(event, value) => {
                          if (value !== null) {
                            //console.log("selecting facture state value", value);
                            //console.log("selecting facture state value", value.id);
                            setProgram(value);
                            //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                          } else {
                            setProgram(null);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select type user" />}
                      />
                    </Box>
                  )}
                  <Box>
                    <Typography fontSize="13px" fontWeight={400} lineHeight="20px" color="#4B465C">
                      Coach
                    </Typography>
                    <Autocomplete
                      sx={{ minWidth: "200px" }}
                      options={users}
                      getOptionLabel={(e) => e.first_name + " " + e.last_name}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      onChange={(event, value) => {
                        if (value !== null) {
                          //console.log("selecting facture state value", value);
                          //console.log("selecting facture state value", value.id);
                          setCoachFil(value);
                          //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                        } else {
                          setCoachFil(null);
                        }
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select coach" />}
                    />
                  </Box>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <CalendarPicker
                        date={selectedDate}
                        maxDate={term?.end_date}
                        minDate={term?.start_date}
                        onChange={(newValue) => {
                          setSelectedDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              )}

              <Box flex={4} borderLeft={showFilters === false ? "none" : "1px solid lightgray"}>
                <Scheduler data={schedule} height={700}>
                  <ViewState onCurrentDateChange={!dateS ? (date) => (new Date(date) < new Date(term?.start_date) ? setSelectedDate(new Date(term?.start_date)) : new Date(term?.start_date) < new Date(date) && new Date(term?.end_date) > new Date(date) ? setSelectedDate(date) : setSelectedDate(new Date(term?.end_date))) : (date) => setDateS(date)} currentDate={dateS ? dateSe : selectedDate} currentViewName={currentViewName} />
                  <WeekView cellDuration={30} startDayHour={6} endDayHour={22} />
                  <MonthView />
                  <DayView cellDuration={30} startDayHour={6} endDayHour={22} />
                  <Appointments appointmentComponent={CustomAppointmentContent} />

                  <Toolbar />
                  <Box marginLeft="20px" display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                      TERM : {term?.name} {court?.name ? " / " : ""} {court?.name}{" "}
                    </Typography>
                    {showFilters === false && (
                      <Box>
                        <Button onClick={() => navigate("/admin/schedules")} variant="primary_active">
                          Go to schedule
                        </Button>
                      </Box>
                    )}
                  </Box>
                  <DateNavigator onCurrentDateChange={(date) => console.log(date)} />
                  <ExternalViewSwitcher currentViewName={currentViewName} />
                  <EditingState />
                  <AppointmentForm
                    visible={visible}
                    onAppointmentDataChange={(data) => {
                      setDate(data?.startDate);
                    }}
                    onVisibilityChange={(e) => {
                      setVisible(e);
                      setEdit(true);
                      setInitialValuesPrv({
                        program: program,
                        level: "",
                        start_time: new Date(),
                        duration: "",
                        coach: "",
                      });
                    }}
                    overlayComponent={() => {
                      return null;
                    }}
                  />
                </Scheduler>
              </Box>

              <Modal open={openDetails?.open} onClose={handleCloseUpdate}>
                <Box sx={modalStyleVertical}>
                  <Box>
                    <Box marginBottom="26px" alignItems="center" display="flex" justifyContent="space-between">
                      <Box>
                        <Typography fontSize="18px" lineHeight="24px" fontWeight="500" color="#4B465C">
                          Schedule session for {moment(openDetails?.data?.start_datetime).format("dddd, MMMM DD, yyyy hh:mm A")}
                        </Typography>
                      </Box>
                      <ButtonSquare action="close" onClick={handleCloseUpdate} />
                    </Box>
                    <Box display="flex" gap="4px" marginBottom="24px">
                      <Box>
                        <Button variant={selectedSessionUpdate === "normal" ? "primary_active" : "primary_inactive"}>Schedule a session</Button>
                      </Box>
                      <Box>
                        <Button
                          onClick={() => {
                            if (selectedSessionUpdate === "normal") {
                              setOpenDetails({
                                open: false,
                                data: null,
                              });
                              setVisible(true);
                              setDate(initialValuesNorml?.start_time);
                              setEdit(true);
                              setSelectedSessionUpdate("private");
                            }
                          }}
                          variant={selectedSessionUpdate !== "normal" ? "primary_active" : "primary_inactive"}
                        >
                          Schedule a private session
                        </Button>
                      </Box>
                    </Box>
                    {selectedSessionUpdate === "normal" && (
                      <Formik onSubmit={(values) => console.log(values)} validationSchema={validationSchema} initialValues={initialValuesNorml} enableReinitialize={true}>
                        {({ errors, setFieldValue, values }) => (
                          <Form>
                            <Box display="flex" flexDirection="column" gap="16px">
                              <Box>
                                <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                  Program
                                </Typography>
                                <Autocomplete
                                  sx={{ minWidth: "300px" }}
                                  options={[]}
                                  value={values?.program}
                                  disabled={!edit}
                                  getOptionLabel={(e) => e.name}
                                  isOptionEqualToValue={(option, value) => option?.id === value.id}
                                  renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select program" />}
                                  onChange={(event, value) => {
                                    if (value !== null) {
                                      //console.log("selecting facture state value", value);
                                      //console.log("selecting facture state value", value.id);
                                      setFieldValue("program", value);
                                      setFieldValue("coach", null);
                                      setProgram(value);
                                      setFieldValue("duration", null);
                                      //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                    } else {
                                      setFieldValue("program", null);
                                    }
                                  }}
                                />
                                <ErrorMessage component={FormikErrorText} name="program" />
                              </Box>

                              <Box>
                                <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                  Level
                                </Typography>
                                <Autocomplete
                                  onChange={(event, value) => {
                                    if (value !== null) {
                                      //console.log("selecting facture state value", value);
                                      //console.log("selecting facture state value", value.id);
                                      setFieldValue("level", value);
                                      //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                    } else {
                                      setFieldValue("level", null);
                                    }
                                  }}
                                  sx={{ minWidth: "300px" }}
                                  options={[]}
                                  disabled={!edit}
                                  value={values?.level ? values?.level : null}
                                  getOptionLabel={(e) => e.name}
                                  isOptionEqualToValue={(option, value) => option?.id === value.id}
                                  renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select level" />}
                                />
                                <ErrorMessage component={FormikErrorText} name="level" />
                              </Box>
                              <Box>
                                <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                  Start time
                                </Typography>
                                <Autocomplete
                                  onChange={(event, value) => {
                                    if (value !== null) {
                                      //console.log("selecting facture state value", value);
                                      //console.log("selecting facture state value", value.id);
                                      setFieldValue("start_time", value);
                                      setStartTime(value);
                                      setFieldValue("coach", null);

                                      //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                    } else {
                                      setFieldValue("start_time", null);
                                      setStartTime(null);
                                      setFieldValue("coach", null);
                                    }
                                  }}
                                  sx={{ minWidth: "300px" }}
                                  options={[]}
                                  disabled={!edit}
                                  value={moment(values?.start_time).format("DD MMMM YYYY hh:mm A")}
                                  getOptionLabel={(e) => e}
                                  isOptionEqualToValue={(option, value) => option === value}
                                  renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select start time" />}
                                />
                                <ErrorMessage component={FormikErrorText} name="start_time" />
                              </Box>
                              <Box>
                                <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                  Duration
                                </Typography>
                                <Autocomplete
                                  sx={{ minWidth: "300px" }}
                                  options={[]}
                                  disabled={!edit}
                                  value={values?.duration ? values?.duration : null}
                                  getOptionLabel={(e) => e?.duration?.toString()}
                                  isOptionEqualToValue={(option, value) => option?.duration === value?.duration}
                                  renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select duration" />}
                                  onChange={(event, value) => {
                                    if (value !== null) {
                                      setFieldValue("duration", value);
                                      setDuration(value);
                                      setFieldValue("coach", null);
                                    } else {
                                      setFieldValue("duration", null);
                                      setDuration(null);
                                    }
                                  }}
                                />
                                <ErrorMessage component={FormikErrorText} name="duration" />
                              </Box>
                              <Box>
                                <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                  Coach
                                </Typography>
                                <FormControl sx={{ height: "42px" }} fullWidth>
                                  <Select
                                    onChange={(e) => {
                                      setFieldValue("coach", e.target?.value);
                                      console.log(e.target?.value, "e log");
                                    }}
                                    disabled={!edit}
                                    value={values?.coach}
                                    placeholder="Select coach"
                                    sx={{ height: "42px", borderColor: "#DEDFE4" }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                  >
                                    <MenuItem key={values?.coach} value={values?.coach}>
                                      <Box display="flex" alignItems="center" gap="5px">
                                        <Box marginTop="5px">{values?.coach?.url_avatar ? <img src={values?.coach?.url_avatar} style={{ height: "32px", width: "32px", borderRadius: "50%" }} alt="proile-pic" /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Box>
                                        <Box>{values?.coach_first_name + " " + values?.coach_last_name}</Box>
                                      </Box>
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                                <ErrorMessage component={FormikErrorText} name="coach" />
                              </Box>
                              <Box display="flex" gap="16px" alignItems="center">
                                {!openDetails?.data?.cancelled_at && (
                                  <Button onClick={() => handleClickCancel(openDetails?.data?.id)} variant="primary_active">
                                    Cancel
                                  </Button>
                                )}
                                <Button onClick={handleCloseUpdate} variant="primary_inactive">
                                  Close
                                </Button>
                                <Button variant="primary_active" onClick={() => navigate("/admin/players/schedule", { state: { data: openDetails?.data } })}>
                                  Go to details
                                </Button>{" "}
                              </Box>
                            </Box>
                          </Form>
                        )}
                      </Formik>
                    )}
                    {selectedSessionUpdate !== "normal" && (
                      <Formik onSubmit={(values) => submitUpdatePrivateCourt(values)} validationSchema={validationSchemaPrivate} initialValues={initialValuesPrv} enableReinitialize={true}>
                        {({ errors, setFieldValue, values }) => (
                          <Form>
                            <Box display="flex" flexDirection="column" gap="16px">
                              <Box>
                                <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                  Start time
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DateTimePicker
                                    value={values.start_time}
                                    onChange={(value) => {
                                      setStartTime(new Date(value));
                                      setFieldValue("start_time", value);
                                      setFieldValue("coach", null);
                                      console.log(value);
                                    }}
                                    ampm={false}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </LocalizationProvider>
                                <ErrorMessage component={FormikErrorText} name="start_time" />
                              </Box>
                              <Box>
                                <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                  Duration
                                </Typography>
                                <Autocomplete
                                  sx={{ minWidth: "300px" }}
                                  options={durations}
                                  disabled={!edit}
                                  value={values?.duration ? values?.duration : null}
                                  getOptionLabel={(e) => e?.duration?.toString()}
                                  isOptionEqualToValue={(option, value) => option?.duration === value?.duration}
                                  renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select duration" />}
                                  onChange={(event, value) => {
                                    if (value !== null) {
                                      setFieldValue("duration", value);
                                      setDuration(value);
                                      setFieldValue("coach", null);
                                    } else {
                                      setFieldValue("duration", null);
                                      setDuration(null);
                                    }
                                  }}
                                />
                                <ErrorMessage component={FormikErrorText} name="duration" />
                              </Box>
                              <Box>
                                <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                  Coach
                                </Typography>
                                <FormControl sx={{ height: "42px" }} fullWidth>
                                  <Select
                                    onChange={(e) => {
                                      setFieldValue("coach", e.target?.value);
                                      console.log(e.target?.value, "e log");
                                    }}
                                    value={values?.coach}
                                    disabled={!edit}
                                    placeholder="Select coach"
                                    sx={{ height: "42px", borderColor: "#DEDFE4" }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                  >
                                    {coachs?.map((coach) => (
                                      <MenuItem key={coach?.id} value={coach?.id}>
                                        <Box display="flex" alignItems="center" gap="5px">
                                          <Box marginTop="5px">{coach?.url_avatar ? <img src={coach?.url_avatar} style={{ height: "32px", width: "32px", borderRadius: "50%" }} alt="proile-pic" /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Box>
                                          <Box>{coach?.first_name + " " + coach?.last_name}</Box>
                                        </Box>
                                      </MenuItem>
                                    ))}
                                    <MenuItem key={values?.coach} value={values?.coach}>
                                      <Box display="flex" alignItems="center" gap="5px">
                                        <Box marginTop="5px">{values?.coach?.url_avatar ? <img src={values?.coach?.url_avatar} style={{ height: "32px", width: "32px", borderRadius: "50%" }} alt="proile-pic" /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Box>
                                        <Box>{values?.coach_first_name + " " + values?.coach_last_name}</Box>
                                      </Box>
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                                <ErrorMessage component={FormikErrorText} name="coach" />
                              </Box>
                              {edit && (
                                <Box display="flex" gap="16px" alignItems="center">
                                  <Button type="submit" variant="primary_active">
                                    Update
                                  </Button>

                                  {!openDetails?.data?.cancelled_at && (
                                    <Button onClick={() => handleClickCancel(openDetails?.data?.id)} variant="primary_active">
                                      Cancel
                                    </Button>
                                  )}
                                  <Button onClick={handleCloseUpdate} variant="primary_inactive">
                                    Close
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      setOpenDetails({
                                        open: false,
                                        data: null,
                                      });
                                      setVisible(true);
                                      setDate(initialValuesPrv?.start_time);
                                      setEdit(true);
                                      setSelectedSessionUpdate("private");
                                    }}
                                    variant="primary_active"
                                  >
                                    Schedule a new private lesson
                                  </Button>
                                </Box>
                              )}
                              <Button variant="primary_active" onClick={() => navigate("/admin/players/schedule", { state: { data: openDetails?.data } })}>
                                Go to details
                              </Button>
                            </Box>
                          </Form>
                        )}
                      </Formik>
                    )}
                  </Box>
                </Box>
              </Modal>
              <Modal open={visible} onClose={() => setVisible(false)}>
                <Box sx={modalStyleVertical}>
                  <Box>
                    <Box marginBottom="26px" alignItems="center" display="flex" justifyContent="space-between">
                      <Box>
                        <Typography fontSize="18px" lineHeight="24px" fontWeight="500" color="#4B465C">
                          Schedule session for {moment(date).format("DD MMMM YYYY")}
                        </Typography>
                      </Box>
                      <ButtonSquare action="close" onClick={() => setVisible(false)} />
                    </Box>
                    <Formik onSubmit={(values) => submitCreatePrivateLesson(values)} validationSchema={validationSchemaPrivate} initialValues={initialValuesPrv} enableReinitialize={true}>
                      {({ errors, setFieldValue, values }) => (
                        <Form>
                          <Box display="flex" flexDirection="column" gap="16px">
                            <Box>
                              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                Start time
                              </Typography>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                  value={values.start_time}
                                  onChange={(value) => {
                                    setStartTime(new Date(value));
                                    setFieldValue("start_time", value);
                                    setFieldValue("coach", null);
                                    console.log(value);
                                  }}
                                  ampm={false}
                                  disableIgnoringDatePartForTimeValidation={true}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </LocalizationProvider>
                              <ErrorMessage component={FormikErrorText} name="start_time" />
                            </Box>
                            <Box>
                              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                Duration
                              </Typography>
                              <Autocomplete
                                sx={{ minWidth: "300px" }}
                                options={durations}
                                disabled={!edit}
                                value={values?.duration ? values?.duration : null}
                                getOptionLabel={(e) => e?.duration?.toString()}
                                isOptionEqualToValue={(option, value) => option?.duration === value?.duration}
                                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select duration" />}
                                onChange={(event, value) => {
                                  if (value !== null) {
                                    setFieldValue("duration", value);
                                    setDuration(value);
                                    setFieldValue("coach", null);
                                  } else {
                                    setFieldValue("duration", null);
                                    setDuration(null);
                                  }
                                }}
                              />
                              <ErrorMessage component={FormikErrorText} name="duration" />
                            </Box>
                            <Box>
                              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                Coach
                              </Typography>
                              <FormControl sx={{ height: "42px" }} fullWidth>
                                <Select
                                  onChange={(e) => {
                                    setFieldValue("coach", e.target?.value);
                                    console.log(e.target?.value, "e log");
                                  }}
                                  value={values?.coach}
                                  disabled={!edit}
                                  placeholder="Select coach"
                                  sx={{ height: "42px", borderColor: "#DEDFE4" }}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                >
                                  {coachs?.map((coach) => (
                                    <MenuItem key={coach?.id} value={coach?.id}>
                                      <Box display="flex" alignItems="center" gap="5px">
                                        <Box marginTop="5px">{coach?.url_avatar ? <img src={coach?.url_avatar} style={{ height: "32px", width: "32px", borderRadius: "50%" }} alt="proile-pic" /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Box>
                                        <Box>{coach?.first_name + " " + coach?.last_name}</Box>
                                      </Box>
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <ErrorMessage component={FormikErrorText} name="coach" />
                            </Box>
                            <Box display="flex" gap="16px" alignItems="center">
                              <Button type="submit" variant="primary_active">
                                Add
                              </Button>

                              <Button onClick={handleCloseUpdate} variant="primary_inactive">
                                Close
                              </Button>
                            </Box>
                          </Box>
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Box>
              </Modal>
            </Box>
          </ContentBox>
          <Modal open={addedScheduleToshow?.open} onClose={() => setAddedScheduleToshow({ open: false, data: null })}>
            <Box sx={modalStyleOverflow}>
              <Box position="absolute" top="2px" right="2px">
                <ButtonSquare action="close" onClick={() => setAddedScheduleToshow({ open: false, data: null })} />
              </Box>
              <Box display="flex" justifyContent="center" marginBottom="16px">
                <Box display="flex" flexDirection="column" gap="16px" width="70%">
                  {addedScheduleToshow?.data?.map((data) => (
                    <Box height="100px" display="flex" justifyContent="center">
                      <CustomAppointmentContent data={data} />
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button onClick={() => setAddedScheduleToshow({ open: false, data: null })} variant="primary_inactive">
                  Close
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal open={openCancelSession?.open} onClose={handleCloseCancel}>
            <Box sx={modalStyleOverflow}>
              <Box position="absolute" top="2px" right="2px">
                <ButtonSquare action="close" onClick={handleCloseCancel} />
              </Box>
              <Box>
                <Typography textAlign="center" fontSize="22px" lineHeight="32px" fontWeight="500" color="#4B465C">
                  Would you like to refund this session ?
                </Typography>
              </Box>
              <Box marginTop="16px" justifyContent="center" display="flex" gap="16px">
                <Button onClick={() => handleCancelAppointement(true)} variant="primary_active">
                  Refund
                </Button>
                <Button onClick={() => handleCancelAppointement(false)} variant="primary_inactive">
                  Do not refund
                </Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      )}
    </Box>
  );
}

export default Schedule;
