export const products_columns = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "PRODUCT",
    width: "20%",
    align: "left",
  },
  {
    name: "CATEGORY",
    width: "15%",
    align: "left",
  },
  {
    name: "PUBLISHED",
    width: "10%",
    align: "left",
  },
  {
    name: "AVAILABLITY",
    width: "10%",
    align: "left",
  },
  {
    name: "PRICE",
    width: "10%",
    align: "left",
  },
  {
    name: "ACTIONS",
    width: "20%",
    align: "left",
  },
];
