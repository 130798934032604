import { Typography, Box } from "@mui/material";
import React from "react";

function BreadcrumComp({ first, second, third }) {
  return (
    <Box>
      <Typography sx={{ display: "inline", fontSize: "22px", color: "#4B465C" }}>
        {first} {second && "/"}{" "}
      </Typography>
      {!third && <Typography sx={{ display: "inline", fontSize: "22px", color: "#000" }}>{second}</Typography>}
      {third && <Typography sx={{ display: "inline", fontSize: "22px", color: "#4B465C" }}>{second + " / "}</Typography>}

      {third && <Typography sx={{ display: "inline", fontSize: "22px", color: "#000" }}>{third}</Typography>}
    </Box>
  );
}

export default BreadcrumComp;
