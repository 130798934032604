import React, { useState, useEffect, useRef } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import ContentBox from "../../components/ui/ContentBox";
import { Typography, Box, TextField, Button, Grid } from "@mui/material";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import FormikErrorText from "../../components/ui/FormikErrorText";
import { modalStyle } from "../../theme/ModalStyle";
import ButtonSquare from "../../components/ui/ButtonSquare";
import * as Yup from "yup";
import PrivateLessonCard from "./PrivateLessonCard";

function PrivateLessons() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const navigate = useNavigate();

  const formRef = useRef();

  const [singPrivateLesson, setSinglePrivateLesson] = useState();
  const [privateLessonsList, setPrivateLessonsList] = useState([]);

  const [players, setPlayers] = useState([[0]]);
  const [didCreate, setDidCreate] = useState(false);
  const [didDelete, setDidDelete] = useState(false);
  const [didUpdate, setDidUpdate] = useState(false);

  const addElementToInnerArray = (innerArrayIndex, newValue) => {
    const newPlayers = [...players];
    const innerArray = [...newPlayers[innerArrayIndex]];
    innerArray.push(newValue);
    newPlayers[innerArrayIndex] = innerArray;
    setPlayers(newPlayers);
  };

  const removeElementFromInnerArray = (innerArrayIndex, elementIndexToRemove) => {
    const newPlayers = [...players];
    const innerArray = [...newPlayers[innerArrayIndex]];
    innerArray.splice(elementIndexToRemove, 1);
    newPlayers[innerArrayIndex] = innerArray;
    setPlayers(newPlayers);
  };

  const removeElementFromOuterArray = (elementIndexToRemove) => {
    const newPlayers = [...players];
    newPlayers.splice(elementIndexToRemove, 1);
    setPlayers(newPlayers);
  };

  const removeAllElementsForm = () => {
    const newPlayers = [[players[0][0]]];
    setPlayers(newPlayers);
  };

  const initialValuesCreatePrivateLesson = {
    duration: "",
    private_lesson_nbr_players: [
      {
        nbr_players: "",
        private_lesson_packs: [
          {
            nbr_lessons: "",
            unit_price: "",
          },
        ],
      },
    ],
  };

  Yup.addMethod(Yup.array, "uniqueNbrLessons", function (message) {
    return this.test("unique-nbr-lessons", message, function (value) {
      const seen = new Set();
      for (let i = 0; i < value?.length; i++) {
        const nbrLessons = value[i]?.nbr_lessons;
        if (seen.has(nbrLessons)) {
          return this.createError({ path: `${this.path}[${i}].nbr_lessons`, message });
        }
        seen.add(nbrLessons);
      }
      return true;
    });
  });

  Yup.addMethod(Yup.array, "uniqueNbrPlayers", function (message) {
    return this.test("unique-nbr-players", message, function (val) {
      const seen = new Set();
      for (let i = 0; i < val?.length; i++) {
        const nbrPlayers = val[i]?.nbr_players;
        if (seen.has(nbrPlayers)) {
          return this.createError({ path: `${this.path}[${i}].nbr_players`, message });
        }
        seen.add(nbrPlayers);
      }
      return true;
    });
  });

  const validationSchemaCreatePrivateLesson = Yup.object().shape({
    duration: Yup.number().typeError("Field must be a number").required("this field is required"),
    private_lesson_nbr_players: Yup.array()
      .of(
        Yup.object().shape({
          nbr_players: Yup.number().typeError("Field must be a number").required("this field is required"),
          private_lesson_packs: Yup.array()
            .of(
              Yup.object().shape({
                nbr_lessons: Yup.number().typeError("Field must be a number").required("this field is required"),
                unit_price: Yup.number().typeError("Field must be a number").required("this field is required"),
              })
            )
            .uniqueNbrLessons("Number of lessons must be unique"),
        })
      )
      .uniqueNbrPlayers("Number of players must be unique"),
  });

  const onSubmitCreatePrivateLesson = (values) => {
    console.log("values", values);
    Api.post(Urls.CREATE_PRIVATE_LESSONS, values, { headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` } })
      .then((response) => {
        if (response.status === 201) toast.success("Private lesson created successfully");
        removeAllElementsForm();
        formRef?.current?.setFieldValue(`private_lesson_nbr_players`, values.private_lesson_nbr_players.slice(0, 1));
        formRef?.current?.setFieldValue(`private_lesson_nbr_players[0].private_lesson_packs`, values.private_lesson_nbr_players[0].private_lesson_packs.slice(0, 1));
        formRef?.current?.resetForm();
        setDidCreate(!didCreate);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  //get private lessons
  useEffect(() => {
    Api.get(Urls.GET_PRIVATE_LESSONS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("private lessons list:", response.data.data);
        setPrivateLessonsList(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, [didDelete, didUpdate, didCreate]);

  return (
    <>
      <ToastContainer />
      <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
        <ToastContainer />
        <Box>
          <Typography fontSize="22px" fontWeight="500" lineHeight="30px" color="#4B465C">
            Private lessons
          </Typography>
        </Box>
        <ContentBox>
          <Box display="flex" flexDirection="column" gap="16px">
            <Box>
              <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                Create private lesson
              </Typography>
            </Box>

            <Formik innerRef={formRef} onSubmit={onSubmitCreatePrivateLesson} validationSchema={validationSchemaCreatePrivateLesson} initialValues={initialValuesCreatePrivateLesson} enableReinitialize={true}>
              {({ errors, setFieldValue, values }) => (
                <Form>
                  <Box>
                    {/* duration field */}
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    {/* <pre>{JSON.stringify(players, null, 2)}</pre> */}
                    <Box sx={{ width: { xs: "100%", md: "50%" } }} marginBottom="10px">
                      <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                        Duration
                      </Typography>
                      <Field fullWidth width="100%" name="duration" type="input" id="duration" as={TextField} autoComplete="off" placeholder="duration" />
                      <ErrorMessage component={FormikErrorText} name="duration" />
                    </Box>
                  </Box>

                  {/* nb players */}
                  {players?.map((player, indexPlayer) => (
                    <Box key={indexPlayer} display="flex" flexDirection="column" gap="16px" sx={{ width: { xs: "100%", md: "50%" }, padding: "16px", bgcolor: "#F3F3F3", borderRadius: "6px", marginBottom: "16px" }}>
                      {indexPlayer !== 0 && (
                        <Box display="flex" flexDirection="row-reverse">
                          <ButtonSquare
                            action="delete_red"
                            onClick={() => {
                              removeElementFromOuterArray(indexPlayer);
                              setFieldValue(
                                `private_lesson_nbr_players`,
                                values.private_lesson_nbr_players.filter((obj) => obj !== values.private_lesson_nbr_players[indexPlayer])
                              );
                            }}
                          />
                        </Box>
                      )}
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Number of players
                        </Typography>
                        <Field fullWidth width="100%" name={`private_lesson_nbr_players[${indexPlayer}].nbr_players`} type="input" id="nbr_players" as={TextField} autoComplete="off" placeholder="Number of players" />
                        <ErrorMessage component={FormikErrorText} name={`private_lesson_nbr_players[${indexPlayer}].nbr_players`} />
                      </Box>

                      {player?.map((pack, indexPack) => (
                        <Box key={indexPack} display="flex" flexDirection="column">
                          <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
                            <Box>
                              <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                Number of sessions
                              </Typography>
                              <Field width="100%" name={`private_lesson_nbr_players[${indexPlayer}].private_lesson_packs[${indexPack}].nbr_lessons`} type="input" id="nbr_players" as={TextField} autoComplete="off" placeholder="Number of sessions" />
                              <ErrorMessage component={FormikErrorText} name={`private_lesson_nbr_players[${indexPlayer}].private_lesson_packs[${indexPack}].nbr_lessons`} />
                            </Box>
                            <Box display="flex" flexDirection="row" alignItems="center" gap="1px">
                              <Box>
                                <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                                  Slot price
                                </Typography>
                                <Field width="100%" name={`private_lesson_nbr_players[${indexPlayer}].private_lesson_packs[${indexPack}].unit_price`} type="input" id="nbr_players" as={TextField} autoComplete="off" placeholder="Unit price" />
                                <ErrorMessage component={FormikErrorText} name={`private_lesson_nbr_players[${indexPlayer}].private_lesson_packs[${indexPack}].unit_price`} />
                              </Box>
                              {indexPack !== 0 && (
                                <Box display="flex" flexDirection="row-reverse" marginBottom="-20px">
                                  <ButtonSquare
                                    action="delete_red"
                                    onClick={() => {
                                      removeElementFromInnerArray(indexPlayer, indexPack);
                                      setFieldValue(
                                        `private_lesson_nbr_players[${indexPlayer}].private_lesson_packs`,
                                        values.private_lesson_nbr_players[indexPlayer].private_lesson_packs.filter((obj) => obj !== values.private_lesson_nbr_players[indexPlayer].private_lesson_packs[indexPack])
                                      );
                                    }}
                                  />
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      ))}

                      <Box>
                        <Button
                          sx={{ height: "38px" }}
                          variant="primary_active"
                          onClick={() => {
                            addElementToInnerArray(indexPlayer, 1);
                          }}
                        >
                          Add a pack
                        </Button>
                      </Box>
                    </Box>
                  ))}

                  <Box marginTop="16px">
                    <Button
                      sx={{ height: "38px" }}
                      variant="primary_active"
                      onClick={() => {
                        setPlayers([...players, [1]]);
                      }}
                    >
                      Add players
                    </Button>
                  </Box>

                  <Box marginTop="16px" display="flex" flexDirection="row-reverse">
                    <Button sx={{ height: "38px" }} variant="primary_active" type="submit">
                      Create private lesson
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </ContentBox>
        <Grid container spacing={3}>
          {privateLessonsList.map((pr, index) => (
            <Grid key={pr.id} item>
              <PrivateLessonCard pr={pr} access_token={access_token} didDelete={didDelete} setDidDelete={setDidDelete} didUpdate={didUpdate} setDidUpdate={setDidUpdate} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}

export default PrivateLessons;
