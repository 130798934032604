import logo from "./imgs/users-icon.svg";
import cart from "./imgs/cart.svg";
import diamond from "./imgs/diamand.svg";
import infos from "./imgs/infos.svg";
import "./App.css";
import { Box, Avatar, Typography, TextField, Button } from "@mui/material";
import CustomButton from "./components/ui/CustomButton";
import { useState } from "react";
import ContentBox from "./components/ui/ContentBox";
import profilepicture from "./imgs/admin-picture.png";
import BreadcrumComp from "./components/ui/BreadcrumComp";
import MySwitch from "./components/ui/MySwitch/MySwitch";
import { NavLink } from "react-router-dom";

function Test() {
  const [selectedButton, setSelectedButton] = useState("Informations");
  const [switchState, setSwitchState] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  const handleMouseEnter = () => {
    setIsHovered(true);

    console.log("mouse entered");
  };

  const handleMouseOut = () => {
    setIsHovered(false);

    console.log("mouse out");
  };

  const buttons = ["Button 1", "Button2"];

  // console.log(selectedButton);
  return (
    <div>
      <Box display="flex" alignItems="center">
        <CustomButton active={selectedButton === "Informations" ? true : false} onClick={() => setSelectedButton("Informations")} variant={selectedButton === "Informations" ? "primary_active" : "primary_inactive"} text="Informations" startIcon={infos} />
        <CustomButton active={selectedButton === "Members" ? true : false} onClick={() => setSelectedButton("Members")} variant={selectedButton === "Members" ? "primary_active" : "primary_inactive"} text="Members" startIcon={logo} />
        <CustomButton active={selectedButton === "Purchases" ? true : false} onClick={() => setSelectedButton("Purchases")} variant={selectedButton === "Purchases" ? "primary_active" : "primary_inactive"} text="Purchases" startIcon={cart} />
        <CustomButton active={selectedButton === "Memberships" ? true : false} onClick={() => setSelectedButton("Memberships")} variant={selectedButton === "Memberships" ? "primary_active" : "primary_inactive"} text="Memberships" startIcon={diamond} />
      </Box>
      <Box display="flex" gap="10px">
        <ContentBox fullwidth flex={1}>
          <Avatar src={profilepicture} />
          <Typography>hello</Typography>
          <Typography>hello</Typography>
          <Typography>hello</Typography>
          <TextField placeholder="hello" />
        </ContentBox>
        <ContentBox fullwidth flex={1}>
          <Avatar src={profilepicture} />
          <Typography>hello</Typography>
          <Typography>hello</Typography>
          <Typography>hello</Typography>
        </ContentBox>
      </Box>
      <Box>
        <BreadcrumComp first="Players" second="Schedules" />
      </Box>
      <Box>
        <MySwitch isOn={switchState} onChange={() => setSwitchState(!switchState)} />
      </Box>

      {buttons.map((b, index) => (
        <Box key={b}>
          <NavLink to={b}>
            <Button
              variant="navbar"
              onMouseOver={(event) => {
                console.log("MOUSE ENTERD");
                // if (content.submenu) {
                // handleClick(event, index);
                //   setSelectedSubMenuCaption(content.submenu[0]?.caption);
                // }
                setAnchorEl(event.currentTarget);
                // setActiveSubmenu(index);
              }}
              onMouseLeave={(event) => {
                console.log("MOUSE OUT");

                // setAnchorEl(null);
                // setActiveSubmenu(null);
              }}
            >
              <Box display="flex" flexDirection="row">
                <Typography>something</Typography>
              </Box>
            </Button>
          </NavLink>
        </Box>
      ))}
    </div>
  );
}

export default Test;
