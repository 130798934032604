import React, { useEffect, useRef, useState } from "react";
import ContentBox from "../../components/ui/ContentBox";
import { Box } from "@mui/material";
import FinanceComponent from "./FinanceComponent";
import Statistics from "./Statistics";
import PopularProducts from "./PopularProducts";
import Purchases from "./Purchases";
import BarChartComponent from "./BarChartComponent";
import Schedule from "../schedule/Schedule";
import { useLocation } from "react-router-dom";
function Dashboard() {
  useEffect(() => {
    document.title = "BTA admin - Dashboard";
  }, []);
  const scheduleRef = useRef();
  const [height, setHeight] = useState(700);
  const location = useLocation();
  const booking = location?.state?.booking ? location?.state?.booking : false;
  console.log(location?.state?.booking);
  useEffect(() => {
    if (booking) {
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 500);
    }
  }, [booking]);
  useEffect(() => {
    setHeight(scheduleRef?.current?.offsetHeight);
  }, [scheduleRef]);

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap="26px">
        <Box display="flex" gap="26px" flexWrap="wrap">
          <Box flex={1}>
            <FinanceComponent />
          </Box>
          <Box flex={1}>
            <Statistics />
          </Box>
        </Box>
        <Box flexWrap="wrap" alignItems="start" display="flex" gap="26px" sx={{ width: "100%" }}>
          <Box flex={1}>
            <BarChartComponent height={height} />
          </Box>
          <Box flex={1} ref={scheduleRef}>
            <Schedule showFilters={false} viewName="Day" dateS={new Date()} />
          </Box>
        </Box>
        <Box display="flex" gap="26px" flexWrap="wrap">
          <Box flex={1}>
            <PopularProducts />
          </Box>
          <Box flex={1}>
            <Purchases />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Dashboard;
