import { Autocomplete, Box, Button, TextField, Typography, Grid, Avatar, Divider, InputAdornment, ListItemButton, ListItemText, FormControl, IconButton } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import MySwitch from "../../../components/ui/MySwitch/MySwitch";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { v4 as uuidv4 } from "uuid";
import Colorful from "@uiw/react-color-colorful";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import styled from "@emotion/styled";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ContentBox from "../../../components/ui/ContentBox";
import * as Yup from "yup";
import uploadIcon from "../../../imgs/upload-icon.svg";
import UserType from "../../../components/ui/UserType";
import TableShowUser from "../../../components/ui/TableShowUser";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { categories_columns } from "../../../components/data/categoriesColumns";
import moment from "moment";
import BreadcrumComp from "../../../components/ui/BreadcrumComp";
import TableShowCategory from "../../../components/ui/TableShowCategory";
import { Formik, ErrorMessage, Field, Form } from "formik";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import { colors } from "../../../theme/Colors";
import MySelect from "../../../components/ui/MySelect";
import { CircularProgress } from "@mui/material";
import VariantsTextField from "../../../components/ui/VariantsTextField";

function UpdateProduct() {
  const params = useParams();
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const navigate = useNavigate();
  const maxSize = 5 * 1024 * 1024;
  const [search, setSearch] = useState("");
  useEffect(() => {
    document.title = "BTA admin - Store / Update product";
  }, []);
  const formRef = useRef();

  console.log(params.id);

  const [categories, setCategories] = useState([]);
  // const [category, setCategory] = useState();
  const [data, setData] = useState([]);
  // const [didCreate, setDidCreate] = useState(false);
  // const [didUpdate, setDidUpdate] = useState(false);
  const [drag, setDrag] = useState(false);
  const [filesToAdd, setFilesToAdd] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [error, setError] = useState("");
  const [isFileVerified, setIsFileVerified] = useState(false);

  const [availability, setAvailability] = useState(false);
  const [published, setPublished] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [existingSizes, setExistingSizes] = useState([]);
  const [sizesToDelete, setSizesToDelete] = useState([]);

  const [colorInstance, setColorInstance] = useState("#FFFFFF");

  const [selectedCategory, setSelectedCategory] = useState();

  const [product, setProduct] = useState();
  // const [initialValues, setInitialValues] = useState([]);

  useEffect(() => {
    Api.get(Urls.STORE_PRODUCT_SHOW + `/${params?.id}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("product is :", response.data.data);
        setProduct(response?.data?.data);
        setAvailability(response?.data?.data?.availability === 1 ? true : false);
        setPublished(response?.data?.data?.is_published === 1 ? true : false);
        setSelectedCategory(response?.data?.data?.category);
        setExistingFiles(response?.data?.data?.files);
        setExistingSizes(response?.data?.data?.variables);
        // setInitialValues(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, []);

  useEffect(() => {
    Api.get(Urls.STORE_GET_CATEGORIES, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("categories list:", response.data.data);
        setCategories(response?.data?.data);
        setData(response?.data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, []);

  const handleDrop = () => {
    setDrag(false);
  };
  const handleDragOver = () => {
    setDrag(true);
  };
  const handleDragLeave = () => {
    setDrag(false);
  };

  const VerifyFile = (e) => {
    const selectedFiles = e.target?.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = [];
      let isError = false;

      for (let i = 0; i < selectedFiles.length; i++) {
        const currentFile = selectedFiles[i];

        if (currentFile.size > maxSize) {
          setError("File size > 5MB");
          setIsFileVerified(false);
          isError = true;
          break;
        }

        newFiles.push(currentFile);
      }

      if (!isError) {
        setError("");
        setIsFileVerified(true);
        setFilesToAdd(newFiles);
        formRef.current.setFieldValue("filesToAdd", newFiles);
      } else {
        setFilesToAdd([]);
      }
    } else {
      setFilesToAdd([]);
      setError("");
      setIsFileVerified(false);
      formRef.current.setFieldValue("filesToAdd", []);
    }
  };

  const initialValues = {
    name: product?.name || "",
    category_id: product?.category?.id || "",
    category: product?.category || { id: "", name: "", url_file: "" },
    description: product?.description || "",
    filesToAdd: [],
    filesToDelete: [],
    existingFiles: product?.files || { id: "", url_file: "" },
    price: product?.price,
    discount_price: "",
    availability: Boolean(product?.availability),
    published: Boolean(product?.is_published),
    size: { idLocal: 0, value: "", availability: true },
  };

  const validateFileType = (file) => {
    if (file) {
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        return true;
      }
    }
    return false;
  };

  const validateFileSize = (file) => {
    if (file) {
      const maxSize = 5 * 1024 * 1024;
      if (file.size <= maxSize) {
        return true;
      }
    }
    return false;
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    category_id: Yup.string().required("Category is required"),
    description: Yup.string(),
    filesToAdd: Yup.array().of(
      Yup.mixed()
        .test("fileType", "Invalid file type", (file) => validateFileType(file))
        .test("fileSize", "File size exceeds 5MB", (file) => validateFileSize(file))
    ),
    price: Yup.number().typeError("Price must be a number").required("Price is required"),
    discount_price: Yup.number().typeError("Discount price must be a number"),
    availability: Yup.boolean().required("Availability field is required"),
    published: Yup.boolean().required("Published field is required"),
  });

  //upload picture
  const [progressUploadFiles, setProgressUploadFiles] = useState(0);

  var configsPic = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      console.log(progressEvent);
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressUploadFiles(percentCompleted);
    },
  };

  const onSubmitUpdateProduct = (values) => {
    const formData = new FormData();
    formData.append("name", values?.name);
    formData.append("category_id", values?.category_id);
    formData.append("description", values?.description);
    values?.filesToAdd.forEach((file) => {
      formData.append("files_to_add[]", file);
    });
    values?.filesToDelete.forEach((file) => {
      formData.append("files_to_delete[]", file);
    });
    formData.append("price", values?.price);
    formData.append("availability", values?.availability === true ? 1 : 0);
    formData.append("is_published", values?.published === true ? 1 : 0);

    sizes?.forEach((size, index) => {
      formData.append(`variables_to_add[${index}][value]`, size.value);
      formData.append(`variables_to_add[${index}][availability]`, size.availability === true ? 1 : 0);
    });

    sizesToDelete?.forEach((size, index) => {
      formData.append(`variables_to_delete[]`, size);
    });

    console.log("FORMDATA", formData);

    Api.post(Urls?.STORE_PRODUCT_UPDATE + `/${params.id}`, formData, configsPic)
      .then((response) => {
        toast.success(response?.data?.message);
        setProgressUploadFiles(0);
        navigate("/admin/store/products");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setProgressUploadFiles(0);
      });
  };

  return (
    <>
      <ToastContainer />
      <Box display="flex" flexDirection="column" gap="26px" margin="26px 0px 300px 0px">
        {/* top header */}
        <Box>
          <BreadcrumComp first="Store" second="Products" third="Update product" />
        </Box>

        <Formik innerRef={formRef} initialValues={initialValues} validationSchema={validationSchema} enableReinitialize={true} onSubmit={onSubmitUpdateProduct}>
          {({ values, setFieldValue, errors }) => (
            <Form>
              {/* header title */}
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" marginBottom="16px">
                <Box display="flex" flexDirection="column" gap="6px">
                  <Typography fontSize="22px" color="#4B465C">
                    Update product #{params.id}
                  </Typography>
                  <Typography fontSize="15px" color="#4B465C">
                    Orders placed across your store
                  </Typography>
                </Box>
                <Box display="flex" gap="26px" height="38px">
                  <Button variant="primary_active" type="submit" startIcon={Boolean(progressUploadFiles) && <CircularProgress variant="determinate" value={progressUploadFiles} size="25px" sx={{ color: "white" }} />}>
                    Update product
                  </Button>
                </Box>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <ContentBox>
                    <Typography sx={{ marginBottom: "16px" }} color="#4B465C" fontSize="16px">
                      Product information
                    </Typography>
                    <Box display="flex" gap="16px" flexDirection="column">
                      <Box>
                        <Typography fontSize="13px" fontWeight={500} color={colors.darkgray}>
                          Name
                        </Typography>
                        <Field autoComplete="off" name="name" placeholder="Choose a name" id="name" as={TextField} fullWidth />
                        <ErrorMessage name="name" component={FormikErrorText} />
                      </Box>
                      <Box>
                        <Typography fontSize="13px" fontWeight={500} color={colors.darkgray}>
                          Category
                        </Typography>
                        <FormControl fullWidth>
                          <Field
                            name="category_id"
                            component={Autocomplete}
                            options={categories}
                            getOptionLabel={(category) => category?.name}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            fullWidth
                            value={values?.category}
                            onChange={(event, value) => {
                              setFieldValue("category_id", value ? value.id : "");
                              setFieldValue("category", value);
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField {...params} name="autocomplete" />
                              </>
                            )}
                            renderOption={(props, option) => (
                              <li {...props}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  {option.url_thumbnail && <Avatar src={option.url_thumbnail} alt={option.name} />}
                                  <span style={{ marginLeft: "8px" }}>{option.name}</span>
                                </div>
                              </li>
                            )}
                          />
                        </FormControl>
                        <ErrorMessage name="category_id" component={FormikErrorText} />
                      </Box>
                      <Box>
                        <Typography fontSize="13px" fontWeight={500} color={colors.darkgray}>
                          Description
                        </Typography>
                        <Field autoComplete="off" name="description" placeholder="Describe your product" id="description" as={TextField} fullWidth multiline rows={3} />
                        <ErrorMessage name="description" component={FormikErrorText} />
                      </Box>
                    </Box>
                  </ContentBox>
                  <Grid container spacing={2} marginTop={0.1}>
                    <Grid item xs={12}>
                      <ContentBox>
                        <Typography sx={{ marginBottom: "16px" }} color="#4B465C" fontSize="16px">
                          Media
                        </Typography>
                        <Box sx={{ cursor: "pointer", minWidth: "200px" }} position="relative">
                          <input
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            accept="image/jpeg , image/png , image/jpg"
                            onChange={(event) => {
                              VerifyFile(event);
                            }}
                            style={{ opacity: "0", zIndex: "0", height: "100%", width: "100%", position: "absolute" }}
                            id="avatar-input"
                            type="file"
                            multiple
                          />
                          <Box justifyContent="center" display="flex">
                            <Box sx={{ width: "100%", transition: "0.2s", background: `${drag ? "rgba(75, 70, 92, 0.1)" : "white"}` }} gap="10px" display="flex" flexDirection="column" alignItems="center" padding="40px 50px" border="2px dashed #DBDADE" borderRadius="6px">
                              <img src={uploadIcon} alt="upload" />
                              <Typography textAlign="center" fontSize="22px" lineHeight="30px" fontWeight="500" color="#4B465C">
                                {/* {!files.length && "Drop files here or click to upload"}
                                {files.length > 0 && (
                                  <ul>
                                    {files.map((file, index) => (
                                      <li key={index}>{file.name}</li>
                                    ))}
                                  </ul>
                                )} */}
                                Drop files here or click to upload
                              </Typography>
                            </Box>
                          </Box>
                        </Box>

                        {existingFiles.length > 0 && (
                          <Box display="flex" flexDirection="row" flexWrap="wrap" gap="20px" margin="16px 0px">
                            {existingFiles.map((file, index) => (
                              <Box key={file?.id} position="relative">
                                <Avatar variant="rounded" src={file?.url_file} sx={{ height: "100px", width: "100px" }} />
                                <Button
                                  variant="primary_active"
                                  onClick={() => {
                                    // setFilesToDelete((prevArray) => [...prevArray, file?.id]);

                                    let newArrayDelete = [...filesToDelete, file?.id];
                                    let newArray = [...existingFiles.slice(0, index), ...existingFiles.slice(index + 1)];
                                    setFilesToDelete(newArrayDelete);
                                    setExistingFiles(newArray);
                                    setFieldValue("filesToDelete", newArrayDelete);
                                    setFieldValue("existingFiles", newArray);
                                  }}
                                  sx={{ position: "absolute", top: -10, right: -10, width: "25px", height: "25px", padding: "5px" }}
                                >
                                  <DeleteOutlineOutlinedIcon htmlColor="white" width="5px" />
                                </Button>
                              </Box>
                            ))}
                          </Box>
                        )}

                        {filesToAdd.length > 0 && (
                          <Box display="flex" flexDirection="row" flexWrap="wrap" gap="20px" margin="16px 0px">
                            {filesToAdd.map((file, index) => (
                              <Box key={index} position="relative">
                                <Avatar variant="rounded" src={URL.createObjectURL(file)} sx={{ height: "100px", width: "100px" }} />
                                <Button
                                  variant="secondary_close"
                                  onClick={() => {
                                    URL.revokeObjectURL(file);
                                    const newArray = [...filesToAdd.slice(0, index), ...filesToAdd.slice(index + 1)];
                                    setFilesToAdd(newArray);
                                    setFieldValue("filesToAdd", newArray);
                                  }}
                                  sx={{ position: "absolute", top: -10, right: -10, width: "25px", height: "25px", padding: "5px" }}
                                >
                                  <CloseRoundedIcon htmlColor="gray" width="5px" />
                                </Button>
                              </Box>
                            ))}
                          </Box>
                        )}

                        {errors.filesToAdd !== null ??
                          errors?.filesToAdd?.map(
                            (errorFile, index) =>
                              errorFile && (
                                <FormikErrorText key={index}>
                                  File n°{index + 1} : {errorFile}
                                </FormikErrorText>
                              )
                          )}

                        <ErrorMessage name="filesToAdd" component={FormikErrorText} />
                      </ContentBox>
                    </Grid>
                    <Grid item xs={12}>
                      <ContentBox>
                        <Box display="flex" gap="16px" flexDirection="column">
                          <Box>
                            <Typography fontSize="13px" fontWeight={500} color={colors.darkgray}>
                              Size
                            </Typography>
                            <Box display="flex" flexDirection="row" gap="10px">
                              <Box flex={1}>
                                <Field autoComplete="off" name="size.value" placeholder="size" id="size" component={VariantsTextField} fullWidth />
                                <ErrorMessage name="size.value" component={FormikErrorText} />
                              </Box>
                              <Box>
                                <Button
                                  sx={{ height: "38px" }}
                                  variant="primary_active"
                                  // onClick={() => {
                                  //   if (values.size.value !== "") {
                                  //     let newArray = [...sizes];
                                  //     newArray.push(values.size);
                                  //     setSizes(newArray);
                                  //   }
                                  // }}
                                  onClick={() => {
                                    if (values.size.value !== "") {
                                      setFieldValue("size.idLocal", uuidv4());
                                      let newArray = [...sizes];
                                      newArray.push(values.size);
                                      setSizes([...sizes, values.size]);
                                    }
                                  }}
                                >
                                  Add size
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                          <Box display="flex" flexDirection="column" maxWidth="300px" gap="10px">
                            {existingSizes.map((size, index) => (
                              <Box key={size.id} sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", bgcolor: "#f8f7fa", padding: "15px", borderRadius: "6px" }}>
                                <Box>
                                  <Typography> Size {`${size.value}`}</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                  <Typography fontSize="15px" fontWeight={500} color={colors.darkgray}>
                                    Available {`${size.id}`}
                                  </Typography>
                                  <MySwitch
                                    isOn={Boolean(size.availability)}
                                    onChange={() => {
                                      let newArray = [...existingSizes];
                                      newArray[index] = { id: size.id, value: size.value, availability: !size.availability };
                                      setExistingSizes(newArray);
                                      setFieldValue("size.availability", !size.availability);
                                    }}
                                  />
                                </Box>
                                <Box>
                                  <ButtonSquare
                                    action="delete_red"
                                    onClick={() => {
                                      // let newArray = [...existingSizes];
                                      // newArray.splice(index, 1);
                                      // setExistingSizes(newArray);
                                      setSizesToDelete([...sizesToDelete, size.id]);
                                      setExistingSizes(existingSizes.filter((s) => s.id != size.id));
                                    }}
                                  />
                                </Box>
                              </Box>
                            ))}

                            {sizes.map((size, index) => (
                              <Box key={index} sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", bgcolor: "#f8f7fa", padding: "15px", borderRadius: "6px" }}>
                                <Box>
                                  <Typography> Size {`${size.value}`}</Typography>
                                </Box>

                                <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                  <Typography fontSize="15px" fontWeight={500} color={colors.darkgray}>
                                    Available
                                  </Typography>
                                  <MySwitch
                                    isOn={size.availability}
                                    onChange={() => {
                                      let newArray = [...sizes];
                                      newArray[index] = { idLocal: size.idLocal, value: size.value, availability: !size.availability };
                                      setSizes(newArray);
                                      setFieldValue("size.availability", !size.availability);
                                    }}
                                  />
                                </Box>
                                <Box>
                                  <ButtonSquare
                                    action="delete_red"
                                    onClick={(index) => {
                                      setSizes(sizes.filter((s) => s.idLocal !== size.idLocal));
                                      // console.log("delete");
                                      // let newArray = [...sizes];
                                      // newArray.splice(index, 1);
                                      // setSizes(newArray);
                                    }}
                                  />
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </ContentBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <ContentBox>
                    <Box display="flex" gap="16px" flexDirection="column">
                      <Box>
                        <Typography fontSize="13px" fontWeight={500} color={colors.darkgray}>
                          Base price
                        </Typography>
                        <Field autoComplete="off" name="price" placeholder="Price" id="price" as={TextField} fullWidth />
                        <ErrorMessage name="price" component={FormikErrorText} />
                      </Box>
                      {/* <Box>
                        <Typography fontSize="13px" fontWeight={500} color={colors.darkgray}>
                          Discount price
                        </Typography>
                        <Field autoComplete="off" name="discount_price" placeholder="Discount price" id="discount_price" as={TextField} fullWidth />
                        <ErrorMessage name="discount_price" component={FormikErrorText} />
                      </Box>
                      <Box>
                        <Typography fontSize="13px" fontWeight={500} color={colors.darkgray}>
                          VAT
                        </Typography>
                        <Field autoComplete="off" name="vat" placeholder="VAT amount" id="vat" as={TextField} fullWidth />
                        <ErrorMessage name="vat" component={FormikErrorText} />
                      </Box> */}
                      <Divider />
                      <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Typography fontSize="15px" fontWeight={500} color={colors.darkgray}>
                          Availability
                        </Typography>
                        <MySwitch
                          isOn={availability}
                          onChange={() => {
                            setAvailability(!availability);
                            setFieldValue("availability", !availability);
                          }}
                        />
                      </Box>
                      <Divider />
                      <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Typography fontSize="15px" fontWeight={500} color={colors.darkgray}>
                          Published
                        </Typography>
                        <MySwitch
                          isOn={published}
                          onChange={() => {
                            setPublished(!published);
                            setFieldValue("published", !published);
                          }}
                        />
                      </Box>
                    </Box>
                  </ContentBox>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                  <pre>{JSON.stringify(errors, null, 2)}</pre>
                  FILES TO DELETE
                  <pre>{JSON.stringify(filesToDelete, null, 2)}</pre>
                  FILES TO ADD
                  <pre>{JSON.stringify(filesToAdd, null, 2)}</pre>
                  MY SIZES
                  <pre>{JSON.stringify(sizes, null, 2)}</pre>
                  EXISTING SIZES
                  <pre>{JSON.stringify(existingSizes, null, 2)}</pre>
                  SIZES TO DELETE
                  <pre>{JSON.stringify(sizesToDelete, null, 2)}</pre> */}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>

        {/* forms */}
      </Box>
    </>
  );
}

export default UpdateProduct;
