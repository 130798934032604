import React from "react";
import { Box, Button, Typography } from "@mui/material";
function CustomButton({ active, onClick, variant, startIcon, text }) {
  return (
    <Button onClick={onClick} sx={{ display: "flex", gap: "8px" }} variant={variant}>
      <Box color="white" display="flex" justifyContent="center">
        <img style={{ filter: `${active ? "grayscale(100%) brightness(0) invert(1)" : "none"}` }} src={startIcon} alt="icon" />
      </Box>
      <Typography fontSize="inherit">{text}</Typography>
    </Button>
  );
}

export default CustomButton;
