import { Autocomplete, Box, Button, TextField, Typography, Chip, Modal, Avatar } from "@mui/material";
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import styled from "@emotion/styled";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ContentBox from "../../components/ui/ContentBox";
import { players_rows } from "../../components/data/playersRows";
import PersonIcon from "@mui/icons-material/Person";

import calendarIcon from "../../imgs/calendarIcon.svg";
import personIcon from "../../imgs/personIcon.svg";

import { player_columns } from "../../components/data/playersColumns";
import * as yup from "yup";

import UserType from "../../components/ui/UserType";
import dropInIcon from "../../imgs/drop.png";

import TableShowUser from "../../components/ui/TableShowUser";
import ButtonSquare from "../../components/ui/ButtonSquare";
import { useLocation, useNavigate } from "react-router-dom";
import phoneIcon from "../../imgs/phoneIcon.svg";

import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { colors } from "../../theme/Colors";
import MySwitch from "../../components/ui/MySwitch/MySwitch";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormikErrorText from "../../components/ui/FormikErrorText";
import { modalStyle, modalStyleOverflow } from "../../theme/ModalStyle";
import BtaDatePicker from "../../components/ui/BtaDatePicker";
import moment from "moment";

function Players() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const navigate = useNavigate();

  const [openEdit, setOpenEdit] = useState({ open: false, data: null });
  const [openDelete, setOpenDelete] = useState({ open: false, data: null });
  const [change, setChange] = useState(false);
  const [search, setSearch] = useState("");
  const [isDropin, setIsDropin] = useState(false);
  const [players, setPlayers] = useState([]);
  const [program, setProgram] = useState(null);
  const [data, setData] = useState([]);
  const [booked, setBooked] = useState(false);
  const [openDropin, setOpenDropin] = useState({ open: false, data: null });
  const [programs, setPrograms] = useState([]);
  const [level, setLevel] = useState(null);
  const [levels, setLevels] = useState([]);
  const [memberships, setMemberships] = useState([]);

  const perPage = "?perPage=10";
  const [initialValues, setIntialValues] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
  });
  //table style
  useEffect(() => {
    document.title = "BTA admin - Players";
  }, []);
  //get memeberships
  useEffect(() => {
    if (openDropin?.open && openDropin?.data?.id) {
      Api.get(Urls.GET_PLAYERS_BOOKING + openDropin?.data?.id + "/bookings", {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((response) => {
          setMemberships(response?.data?.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  }, [openDropin]);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      paddingTop: "8px",
      position: "relative",
      paddingBottom: "8px",
      letterSpacing: "1px",
      color: `#4B465C`,
      fontSize: 13,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      background: "white",
      fontSize: 14,
      color: `#666D83`,
      fontWeight: "500",
    },
  }));
  const handleClickEdit = (data) => {
    setOpenEdit({ open: true, data: data });
    setIntialValues({
      first_name: data?.first_name,
      last_name: data?.last_name,
      date_of_birth: data?.date_of_birth,
    });
  };
  //get list progs
  useEffect(() => {
    console.log(Urls.GET_PROGRAMS, "PROGRAMS");
    Api.get(Urls.GET_PROGRAMS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("programs list:", response.data.data);
        setPrograms(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },

    ":hover": {
      filter: "brightness(0.95)",
    },
    cursor: "pointer",
    zIndex: "0",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "0px",
  });
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    Api.get(Urls?.GET_LEVELS, {
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setLevels(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, []);
  const validationSchema = yup.object({
    first_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    last_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    date_of_birth: yup.date().required("This field is mandatory"),
  });
  //get list players
  useEffect(() => {
    Api.get(Urls.GET_PLAYERS_LIST + perPage, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(search && { search: search }),
        ...(program && { programId: program }),
        ...(level && { levelId: level }),
        ...(booked ? { is_booking: 0 } : { is_booking: 1 }),

        is_dropin: isDropin ? 1 : 0,
      },
    })
      .then((response) => {
        console.log("players list:", response.data.data);
        setPlayers(response?.data?.data);
        setData(response?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, [isDropin, search, program, level, change, booked]);
  //handle Pagination
  const handlePagination = (link) => {
    console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log("RESULTAT MATCHED offres DATA", res?.data);
        console.log("RESPONES GHERBI", res);
        setData(res?.data);
        setPlayers(res.data?.data);
        console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        console.log(err);
        setPlayers([]);
      });
  };
  const handleUpdatePlayer = (values) => {
    Api.patch(
      Urls?.UPDATE_PLAYER + openEdit?.data?.id,
      {
        first_name: values?.first_name,
        last_name: values?.last_name,
        date_of_birth: values?.date_of_birth,
      },
      {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success(res?.data?.message);
        setOpenEdit({ open: false, data: null });
        setChange(!change);
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
      });
  };
  const handleDeletePlayer = () => {
    Api.delete(Urls?.UPDATE_PLAYER + openDelete?.data?.id, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        toast?.success(res?.data?.message);
        setOpenDelete({ open: false, data: null });
        setChange(!change);
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
      });
  };
  return (
    <>
      <ToastContainer />
      <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
        <Box>
          <Typography fontSize="22px" fontWeight="500" lineHeight="30px" color="#4B465C">
            Players
          </Typography>
        </Box>
        <ContentBox>
          <Box display="flex" flexDirection="column" gap="16px">
            <Box>
              <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                List of Players
              </Typography>
            </Box>
            <Box display="flex" flexWrap="wrap" gap="16px" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap="16px">
                <Box display="flex" gap="10px" flexDirection="row" alignItems="center">
                  <Typography fontSize="20px" fontWeight={600} color={colors.darkgray}>
                    Guest drop-in
                  </Typography>
                  <MySwitch
                    isOn={isDropin}
                    onChange={() => {
                      setIsDropin(!isDropin);
                    }}
                  />
                </Box>
                <Box display="flex" gap="10px" flexDirection="row" alignItems="center">
                  <Typography fontSize="20px" fontWeight={600} color={colors.darkgray}>
                    Not booked
                  </Typography>
                  <MySwitch
                    isOn={booked}
                    onChange={() => {
                      setBooked(!booked);
                    }}
                  />
                </Box>
              </Box>
              <Box marginTop="8px" display="flex" flexWrap="wrap" gap="16px" flexDirection="row" justifyContent="flex-end">
                <TextField value={search} onChange={handleSearchChange} type="input" placeholder="Search player" />
                <Box>
                  <Autocomplete
                    sx={{ minWidth: "200px" }}
                    options={programs}
                    getOptionLabel={(e) => e.name}
                    isOptionEqualToValue={(option, value) => option?.id === value}
                    onChange={(event, value) => {
                      if (value !== null) {
                        //console.log("selecting facture state value", value);
                        //console.log("selecting facture state value", value.id);
                        setProgram(value?.id);
                        //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                      } else {
                        setProgram(null);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select program" />}
                  />
                </Box>
                <Box>
                  <Autocomplete
                    sx={{ minWidth: "200px" }}
                    options={levels}
                    getOptionLabel={(e) => e.name}
                    isOptionEqualToValue={(option, value) => option?.id === value}
                    onChange={(event, value) => {
                      if (value !== null) {
                        //console.log("selecting facture state value", value);
                        //console.log("selecting facture state value", value.id);
                        setLevel(value?.id);
                        //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                      } else {
                        setLevel(null);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select levels" />}
                  />
                </Box>
              </Box>
            </Box>
            <TableContainer sx={{ borderRadius: "0px", border: `1px solid #DBDADE` }}>
              <StyledTable aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {player_columns.map((col, index) => (
                      <StyledTableCell key={index} width={col.width} align={col.align}>
                        {col.name}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {players?.map((player, index) => (
                    <StyledTableRow key={player?.id}>
                      <StyledTableCell onClick={() => (player?.is_dropin ? setOpenDropin({ open: true, data: player }) : navigate(`/admin/players/${player.id}`))} align="left">
                        <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                          #{player?.id}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell onClick={() => (player?.is_dropin ? setOpenDropin({ open: true, data: player }) : navigate(`/admin/players/${player.id}`))} align="left">
                        <Box>
                          <TableShowUser url_photo={player?.url_avatar} email={player?.email} full_name={player?.first_name + " " + player?.last_name} />
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell onClick={() => (player?.is_dropin ? setOpenDropin({ open: true, data: player }) : navigate(`/admin/players/${player.id}`))} align="left">
                        {player.gender}
                      </StyledTableCell>
                      <StyledTableCell onClick={() => (player?.is_dropin ? setOpenDropin({ open: true, data: player }) : navigate(`/admin/players/${player.id}`))} align="left">
                        {player.date_of_birth}
                      </StyledTableCell>
                      <StyledTableCell onClick={() => (player?.is_dropin ? setOpenDropin({ open: true, data: player }) : navigate(`/admin/players/${player.id}`))} align="center">
                        <Chip variant="primary" label={player.program?.name} />
                      </StyledTableCell>
                      <StyledTableCell onClick={() => (player?.is_dropin ? setOpenDropin({ open: true, data: player }) : navigate(`/admin/players/${player.id}`))} align="center">
                        <Chip variant="primary" label={player.level_name} />
                      </StyledTableCell>
                      <StyledTableCell onClick={() => (player?.is_dropin ? setOpenDropin({ open: true, data: player }) : navigate(`/admin/players/${player.id}`))} align="left">
                        <Box display="flex" gap="10px" flexWrap="wrap">
                          {player?.bookings?.length > 0 ? "Booked" : "Not booked"}
                        </Box>
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        <Box justifyContent="left" display="flex" gap="10px" alignItems="center">
                          <ButtonSquare onClick={() => handleClickEdit(player)} action="edit_outlined" />
                          <ButtonSquare onClick={() => setOpenDelete({ open: true, data: player })} action="delete_red" />
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
            <Box display="flex" gap="10px" flexDirection="row-reverse">
              <Box>
                {data?.links?.next && (
                  <>
                    <Button
                      onClick={() => {
                        handlePagination(data?.links?.next);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      variant="mj_secondary_md"
                    >
                      Next
                    </Button>
                  </>
                )}
              </Box>

              <Box display="flex" gap="10px">
                {data?.meta?.links?.map((link, index) => (
                  <ButtonSquare
                    key={index}
                    onClick={() => {
                      handlePagination(link.url);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action={link?.active ? "number-active" : "number"}
                    number={link.label}
                  />
                ))}
              </Box>

              <Box>
                {data?.links?.prev && (
                  <>
                    <Button
                      onClick={() => {
                        handlePagination(data?.links?.prev);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      variant="mj_secondary_md"
                    >
                      Previous
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Modal open={openEdit?.open} onClose={() => setOpenEdit({ open: false, data: null })}>
            <Box sx={modalStyleOverflow}>
              <Box position="absolute" top="2px" right="2px">
                <ButtonSquare action="close" onClick={() => setOpenEdit({ open: false, data: null })} />
              </Box>
              <Box>
                <Formik onSubmit={(values) => handleUpdatePlayer(values)} validationSchema={validationSchema} initialValues={initialValues} enableReinitialize={true}>
                  {({ errors, setFieldValue, values }) => (
                    <Form>
                      <Box marginBottom="26px">
                        <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                          Update player
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection="column" gap="16px">
                        <Box display="flex" sx={{ gap: { xs: "8px", sm: "8px", md: "16px", lg: "26px" } }}>
                          <Box flex={1}>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              First name
                            </Typography>
                            <Field style={{ width: "100%" }} name="first_name" type="input" id="first_name" as={TextField} autoComplete="off" placeholder="John" />
                            <ErrorMessage component={FormikErrorText} name="first_name" />
                          </Box>
                          <Box flex={1}>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              Last name
                            </Typography>
                            <Field style={{ width: "100%" }} name="last_name" type="input" id="last_name" as={TextField} autoComplete="off" placeholder="Doe" />
                            <ErrorMessage component={FormikErrorText} name="last_name" />
                          </Box>
                        </Box>

                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            Date of birth{" "}
                          </Typography>
                          <Field fullWidth width="100%" name="date_of_birth" id="date_of_birth" component={BtaDatePicker} disablePast={true} maxDate={new Date()} autoComplete="off" placeholder="Select a date..." />
                          <ErrorMessage component={FormikErrorText} name="date_of_birth" />
                        </Box>

                        <Box marginTop="10px" justifyContent="center" display="flex" gap="16px">
                          <Button type="submit" variant="primary_active">
                            update
                          </Button>
                          <Button onClick={() => setOpenEdit({ open: false, data: null })} variant="primary_inactive">
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Modal>
          <Modal open={openDelete?.open} onClose={() => setOpenDelete({ open: false, data: null })}>
            <Box sx={modalStyleOverflow}>
              <Box position="absolute" top="2px" right="2px">
                <ButtonSquare action="close" onClick={() => setOpenDelete({ open: false, data: null })} />
              </Box>
              <Box marginBottom="26px">
                <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                  Delete player
                </Typography>
              </Box>
              <Box>
                <Typography textAlign="center" fontSize="22px" lineHeight="32px" fontWeight="500" color="#4B465C">
                  Are you sure you want to delete this player (id : {openDelete?.data?.id})?
                </Typography>
              </Box>
              <Box marginTop="16px" justifyContent="center" display="flex" gap="16px">
                <Button onClick={handleDeletePlayer} variant="primary_active">
                  Delete
                </Button>
                <Button onClick={() => setOpenDelete({ open: false, data: null })} variant="primary_inactive">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal open={openDropin?.open} onClose={() => setOpenDropin({ open: false, data: null })}>
            <Box sx={modalStyleOverflow}>
              <Box position="absolute" top="2px" right="2px">
                <ButtonSquare action="close" onClick={() => setOpenDropin({ open: false, data: null })} />
              </Box>
              <Box display="flex" flexDirection="column" gap="16px">
                <Box alignItems="center" display="flex" flexDirection="column" gap="16px">
                  <img style={{ height: "101px", width: "auto" }} src={dropInIcon} alt="dropin" />

                  <Box>
                    <Typography fontWeight="600" fontSize="24px">
                      {openDropin?.data?.first_name} {openDropin?.data?.last_name}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap="16px">
                  <Typography fontWeight="400" fontSize="15px" lineHeight="20px" color="#4B465C">
                    ABOUT
                  </Typography>
                  <Box display="flex" gap="6px" alignItems="start">
                    <img src={calendarIcon} alt="position" />
                    <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                      Date of birth
                    </Typography>
                    <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                      {openDropin?.data?.date_of_birth ? moment(openDropin?.data?.date_of_birth).format("dddd, MMMM DD, yyyy") : "-"}
                    </Typography>
                  </Box>
                  <Box display="flex" gap="6px" alignItems="start">
                    <img src={personIcon} alt="position" />

                    <Typography fontSize="15px" lineHeight="22px" fontWeight="600" color="#4B465C">
                      Gender
                    </Typography>
                    <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                      {openDropin?.data?.gender ? openDropin?.data?.gender : "-"}
                    </Typography>
                  </Box>
                  <Typography fontWeight="400" fontSize="15px" lineHeight="20px" color="#4B465C">
                    BOOKINGS
                  </Typography>
                  <Box display="flex" flexDirection="column" gap="10px">
                    {memberships?.map((membership) => (
                      <Box display="flex" alignItems="center" gap="10px">
                        <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                          #{membership?.id}
                        </Typography>
                        <Typography>Start date : {moment(membership?.start_date).format("dddd, MMMM DD, yyyy")}</Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        </ContentBox>
      </Box>
    </>
  );
}

export default Players;
