export const programs_columns = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "PROGRAM NAME",
    width: "25%",
    align: "left",
  },
  {
    name: "N° PLAYERS",
    width: "20%",
    align: "center",
  },
  {
    name: "LEVELS",
    width: "30%",
    align: "left",
  },
  {
    name: "ACTION",
    width: "20%",
    align: "left",
  },
];
