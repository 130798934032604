export const purchases_columns_client = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },

  {
    name: "TOTAL",
    width: "20%",
    align: "left",
  },
  {
    name: "Issued Date",
    width: "20%",
    align: "left",
  },
  {
    name: "PAYMENT",
    width: "20%",
    align: "left",
  },
  {
    name: "ORDER STATUS",
    width: "20%",
    align: "center",
  },
  {
    name: "ACTIONS",
    width: "15%",
    align: "left",
  },
];
