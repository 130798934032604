import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import financeIcon from "../../imgs/finance.svg";
import ContentBox from "../../components/ui/ContentBox";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import { ToastContainer, toast } from "react-toastify";
function FinanceComponent() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [loading, setLoading] = useState(true);
  const [data, setDate] = useState();
  useEffect(() => {
    Api.get(Urls.GET_TOTAL_REV, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res);
        setDate(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        toast.error(err?.response?.data?.message);
      });
  }, []);
  return (
    <Box>
      <ContentBox>
        <ToastContainer />
        {loading && (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <Box display="flex" flexWrap="wrap" justifyContent="space-between">
            <Box display="flex" flexDirection="column" gap="10px">
              <Box>
                <Box>
                  <Typography fontSize="18px" fontWeight="500" lineHeight="22px" color="#4B465C">
                    BTA Revenue 🎉
                  </Typography>
                </Box>
                {/*         <Box>
                <Typography fontSize="15px" fontWeight="400" lineHeight="22px" color="#4B465C">
                  Summer season 2019
                </Typography>
  </Box> */}
              </Box>
              <Box>
                <Typography fontSize="22px" fontWeight="500" lineHeight="38px" color="#EF0000">
                  {parseFloat(data).toLocaleString("fr-FR")} BHD
                </Typography>
              </Box>
            </Box>

            <Box>
              <img src={financeIcon} alt="finance" />
            </Box>
          </Box>
        )}
      </ContentBox>
    </Box>
  );
}

export default FinanceComponent;
