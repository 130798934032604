import { useRef, useState } from "react";

export function usePointerDifference(onChange) {
  const [difference, setDifference] = useState();
  const start = useRef(0);
  function handlePointerDown(event) {
    window.addEventListener("mousemove", handlePointerMove);
    window.addEventListener("mouseup", handlePointerUp);
    window.addEventListener("touchmove", handlePointerMove);
    window.addEventListener("touchend", handlePointerUp);
    window.addEventListener("touchcancel", handlePointerUp);
  }
  function handlePointerMove(event) {
    event.preventDefault();
    if (!start.current) {
      start.current = event.pageX;
    }
    setDifference((start.current - event.pageX) * -1);
  }
  function handlePointerUp(event) {
    start.current = 0;
    // hack to prevent onClick from firing to soon before clearing difference
    // this should be fixed in Switch component
    setTimeout(() => setDifference(0));
    window.removeEventListener("mousemove", handlePointerMove);
    window.removeEventListener("mouseup", handlePointerUp);
    window.removeEventListener("touchmove", handlePointerMove);
    window.removeEventListener("touchend", handlePointerUp);
    window.removeEventListener("touchcancel", handlePointerUp);
  }
  return [
    {
      onMouseDown: handlePointerDown,
      onTouchStart: handlePointerDown,
    },
    difference,
  ];
}
