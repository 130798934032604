import React from "react";
import { Box } from "@mui/material";
import UserInfos from "../../../components/profileComponents/UserInfos";
import { cardInfos } from "../../../components/data/AdminProfileInfos";
function UserInformations({ userInfos, id }) {
  return (
    <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
      <UserInfos birthday={userInfos?.date_of_birth} type="coach" id={id} fullName={userInfos?.first_name + " " + userInfos?.last_name} location={cardInfos?.location} contact={userInfos?.phone_number} email={userInfos?.email} />
    </Box>
  );
}

export default UserInformations;
