import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import UserInfos from "../../../components/profileComponents/UserInfos";
import { cardInfos } from "../../../components/data/AdminProfileInfos";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import infos from "../../../imgs/infos.svg";

import { Urls } from "../../../api/Urls";
import UserCard from "../../../components/profileComponents/UserCard";
import CustomButton from "../../../components/ui/CustomButton";
import { useParams } from "react-router-dom";
import BreadcrumComp from "../../../components/ui/BreadcrumComp";
function AdminDetails({ isAdminUpdated, setIsAdminUpdated }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [userData, setUserData] = useState({});
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [updateInfos, setUpdateInfos] = useState(false);

  useEffect(() => {
    Api.get(Urls?.GET_USER + id, {
      headers: { "content-type": "application", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setUserData(res?.data?.data);
        setLoading(false);
        console.log(res?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [updateInfos]);
  return (
    <>
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
          <Box>
            <BreadcrumComp first="Users" second={userData?.first_name + " " + userData?.last_name} />
          </Box>
          <Box>
            <CustomButton variant="primary_active" active={true} text="Informations" startIcon={infos} />
          </Box>
          <UserCard
            isAdminUpdated={isAdminUpdated}
            setIsAdminUpdated={setIsAdminUpdated}
            updateInfos={updateInfos}
            setUpdateInfos={setUpdateInfos}
            edit={true}
            type="admin"
            id={id}
            fullName={userData?.first_name + " " + userData?.last_name}
            joined={userData?.created_at?.split(" ")[0]}
            contact={userData?.phone_number}
            email={userData?.email}
            url_avatar={userData?.url_avatar}
          />
          <UserInfos birthday={userData?.date_of_birth} type="coach" id={id} fullName={userData?.first_name + " " + userData?.last_name} contact={userData?.phone_number} email={userData?.email} />
        </Box>
      )}
    </>
  );
}

export default AdminDetails;
