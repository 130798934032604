import React, { useEffect, useState } from "react";
import { Box, Avatar, Typography, Chip, Button, FormControl, Select, MenuItem, TextField, Autocomplete, Modal, CircularProgress, IconButton } from "@mui/material";
import ContentBox from "../../components/ui/ContentBox";
import calendarIcon from "../../imgs/calendarIcon.svg";
import PersonIcon from "@mui/icons-material/Person";
import CustomButton from "../../components/ui/CustomButton";
import BreadcrumComp from "../../components/ui/BreadcrumComp";
import infos from "../../imgs/infos.svg";
import { useNavigate } from "react-router-dom";
import awardsIcon from "../../imgs/awards.png";
import PlayerInformations from "./PlayerInformations";
import PlayerMemberships from "./PlayerMemberships";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import uploadIcon from "../../imgs/upload-icon.svg";
import iconBronze from "../../imgs/iconBronze.png";
import iconGold from "../../imgs/iconGold.png";
import iconSilver from "../../imgs/iconSilver.png";
import { useAuthContext } from "../../hooks/useAuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment, { duration } from "moment";
import * as yup from "yup";
import { modalStyle, modalStyleOverflow, modalStyleVertical } from "../../theme/ModalStyle";
import FormikErrorText from "../../components/ui/FormikErrorText";
import { ErrorMessage, Field, Form, Formik } from "formik";
import ButtonSquare from "../../components/ui/ButtonSquare";
import BtaDatePicker from "../../components/ui/BtaDatePicker";
import { colors } from "../../theme/Colors";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function PlayerDetails() {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const location = useLocation();
  const [coach, setCoach] = useState(null);
  const bookId = location?.state?.bookId;
  const [openEditAwards, setOpenEditAwards] = useState({ open: false, data: null });
  const [privateLessonPack, setPrivateLessonPack] = useState([]);
  const content = ["Informations", "Memberships"];
  const [selectedContent, setSelectedContent] = useState(bookId ? content[1] : content[0]);
  const [openAddBooking, setOpenAddBooking] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [privateLessonPacks, setPrivateLessonPacks] = useState([]);
  const [privateLessonPackLessons, setPrivateLessonPackLessons] = useState([]);
  const [maxDate, setMaxDate] = useState();
  const [selectedBookingType, setselectedBookingType] = useState("program");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [progressUpdatePic, setProgressUpdatePic] = useState(0);
  var configsPic = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressUpdatePic(percentCompleted);
    },
  };
  const [terms, setTerms] = useState([]);
  const [selectedTermId, setSelectedTermId] = useState(null);
  const [planning, setPlanning] = useState([]);
  const [program, setProgram] = useState(null);
  const [privateLesson, setPrivateLesson] = useState(null);
  const [change, setChange] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [drag, setDrag] = useState(false);
  const [file, setFile] = useState(null);
  const [levels, setLevels] = useState([]);
  const [duration, setDuration] = useState([]);
  const [durations, setDurations] = useState([]);
  const [level, setLevel] = useState(null);
  const [error, setError] = useState("");
  const [isFileVerified, setIsFileVerified] = useState(false);
  const [plannings, setPlannings] = useState([]);
  const navigate = useNavigate();
  const [openChangePic, setOpenChangePic] = useState(false);
  const maxSize = 5 * 1024 * 1024;
  const [openEdit, setOpenEdit] = useState({ open: false, data: null });
  const [openDelete, setOpenDelete] = useState({ open: false, data: null });
  const [errorPlanning, setErrorPlanning] = useState("");
  const [frequencies, setFrequencies] = useState([]);
  const [initialValuesAwards, setInitialValuesAwards] = useState({
    awards: "",
  });
  const [changePlayer, setChangePlayer] = useState(false);
  const [initialValuesPlayer, setIntialValues] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
  });

  const validationSchemaPlayer = yup.object({
    first_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    last_name: yup.string().required("This field is mandatory").min(3, "3 Caracters minimum"),
    date_of_birth: yup.date().required("This field is mandatory"),
  });
  const [initialValues, setInitialValuesUpdate] = useState({
    term: "",
    program: "",
    level: "",
    duration: "",
    frequency: "",
    plannings: [],
  });
  const [initialValuesPrivate, setInitialValuesPrivate] = useState({
    privateLesson: "",
    coach_id: "",
    private_lesson_pack: "",
    lessons_id: "",
    plannings: [],
  });
  const validationSchemaAwards = yup.object({
    awards: yup?.string().required("This field is required"),
  });
  const handleClickEdit = (data) => {
    setOpenEdit({ open: true, data: data });
    setIntialValues({
      first_name: data?.first_name,
      last_name: data?.last_name,
      date_of_birth: data?.date_of_birth,
    });
  };
  const validationSchemaPrivate = yup.object({
    privateLesson: yup.object().shape({ id: yup?.string().required("This field is required") }),
    coach_id: yup?.object().shape({ id: yup?.string().required("This field is required") }),
    private_lesson_pack: yup?.object().shape({ id: yup?.string().required("This field is required") }),
    lessons_id: yup?.object().shape({ id: yup?.string().required("This field is required") }),
  });
  const validationSchema = yup.object({
    term: yup.object().shape({ id: yup?.string().required("This field is required") }),
    program: yup.object().shape({ id: yup?.string().required("This field is required") }),
    duration: yup?.object().shape({ duration: yup?.string().required("This field is required") }),
    level: yup?.object().shape({ id: yup?.string().required("This field is required") }),
    frequency: yup?.object().shape({ id: yup?.string().required("This field is required") }),
  });
  const param = useParams();
  const [player, setPlayer] = useState({});
  useEffect(() => {
    document.title = "BTA admin - Player details";
  }, []);
  const [questions, setQuestions] = useState([]);
  const [objectResponses, setObjectResponses] = useState({});
  useEffect(() => {
    let object = {};
    if (player) {
      Api.get(Urls?.GET_PLAYER_QUESTIONS, {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          date_of_birth: player?.date_of_birth,
        },
      })
        .then((res) => {
          setQuestions(res?.data?.data);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  }, [player]);
  useEffect(() => {
    let object = {};
    if (dateOfBirth !== null) {
      Api.get(Urls?.GET_PLAYER_QUESTIONS, {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
        params: {
          date_of_birth: moment(dateOfBirth).format("yyyy-MM-DD"),
        },
      })
        .then((res) => {
          setQuestions(res?.data?.data);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  }, [dateOfBirth]);
  const formatDate = (date) => {
    const parsedDate = moment(date, "YYYY-MM-DD HH:mm:ss");
    const formattedDate = parsedDate.format("D MMMM YYYY");
    return formattedDate;
  };
  const VerifyFile = (e) => {
    if (e.target?.files[0]) {
      if (!e.target?.files[0].size <= maxSize) {
        setError("File size > 5MB");
        setIsFileVerified(false);
      }
      if (e.target?.files[0].size <= maxSize) {
        setError("");
        setIsFileVerified(true);
        setFile(e.target?.files[0]);
      }
    } else {
      setFile(null);
    }
  };
  //get player
  useEffect(() => {
    console.log("param", param?.id);
    Api.get(Urls.GET_PLAYER + `/${param?.id}`, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        console.log("single player:", response.data.data);
        setPlayer(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, [changePlayer]);
  // Get terms
  useEffect(() => {
    Api.get(Urls.GET_TERMS_LIST, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((res) => {
        setTerms(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  }, []);
  //get list progs
  useEffect(() => {
    if (selectedBookingType === "program") {
      Api.get(Urls.GET_PROGRAMS, {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      })
        .then((response) => {
          console.log("programs list:", response.data.data);
          setPrograms(response?.data?.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      Api.get(Urls.GET_PRIVATE_LESSONS, {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      })
        .then((response) => {
          console.log("programs list:", response.data.data);
          setPrograms(response?.data?.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  }, [selectedBookingType]);
  const contentSelector = (content) => {
    switch (content) {
      case "Informations":
        return <PlayerInformations player={player} />;
      case "Memberships":
        return <PlayerMemberships bookId={bookId} cha={change} id={param?.id} />;
      default:
        return;
    }
  };
  useEffect(() => {
    if (!program) {
      setDurations([]);
      setLevels([]);
      setFrequencies([]);
    } else {
      setDurations(program?.program_durations);
      setLevels(program?.levels);
    }
  }, [program]);
  useEffect(() => {
    console.log(duration);
    setFrequencies(duration?.program_duration_frequencies);
  }, [duration]);
  useEffect(() => {
    if (duration && level) {
      Api.get(Urls?.GET_PLANNING, {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
        params: {
          program_duration_id: duration?.id,
          level_id: level?.id,
          term_id: selectedTermId,
        },
      })
        .then((res) => {
          console.log(res);
          setPlannings(res?.data?.data);
        })
        .catch((err) => {
          console.log(err);
          toast?.error(err?.response?.data?.message);
        });
    }
  }, [duration, level, selectedTermId]);
  const handleSubmitCreateBooking = (values) => {
    if (planning?.length === 0) {
      setErrorPlanning("this field is required");
    } else {
      console.log(values);
      setErrorPlanning("");
      Api.post(
        Urls?.POST_PLANNING,
        {
          selected_court_weekly_schedule_ids: planning?.map((plan) => plan?.id), // ID CourtWeeklyschedule
          selected_program_duration_frequency_id: values?.frequency?.id, // ID Program duration frequancy
        },
        {
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${access_token}`,
          },
        }
      )
        .then((res) => {
          console.log(res);
          Api.post(
            Urls.POST_ADD_BOOKING,
            {
              user_id: player?.client?.id,
              player_id: player?.id,
              program_duration_frequency_id: values?.frequency?.id,
              confirmed_court_schedule_ids: res?.data?.data?.map((d) => d?.id),
            },
            {
              headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
            }
          )
            .then((res) => {
              console.log(res);
              toast.success(res?.data?.message);
              setChange(!change);
              setOpenAddBooking(false);
              setPlanning([]);
            })
            .catch((err) => {
              console.log(err);
              toast.error(err?.response?.data?.message);
            });
        })
        .catch((err) => {
          console.log(err);
          toast.error(err?.response?.data?.message);
        });
    }
  };
  const createBookingPrivate = (values) => {
    Api.post(
      Urls.POST_ADD_BOOKING,
      {
        user_id: player?.client?.id,
        player_id: player?.id,
        private_lesson_pack_id: values?.lessons_id?.id,
        confirmed_court_schedule_ids: planning.map((p) => p.id),
      },
      {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        setChange(!change);
        setOpenAddBooking(false);
        setPlanning([]);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const [errorResponses, setErrorResponses] = useState(false);
  const handleUpdatePlayer = (values) => {
    let error = false;
    let responseObject = {};
    for (let i = 0; i < questions.length; i++) {
      responseObject[`answer_question_${i + 1 === 1 ? "one_id" : i + 1 === 2 ? "two_id" : "three_id"}`] = questions[i]?.response;
      if (questions[i]?.response === undefined) {
        setErrorResponses(true);
        console.log(questions[i]?.response);
        error = true;
      }
    }
    let data = { ...values, ...responseObject };
    if (!error) {
      Api.patch(Urls?.UPDATE_PLAYER + openEdit?.data?.id, data, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          toast?.success(res?.data?.message);
          setOpenEdit({ open: false, data: null });
          setChangePlayer(!changePlayer);
          setErrorResponses(false);
        })
        .catch((err) => {
          toast?.error(err?.response?.data?.message);
        });
    }
  };
  const handleDeletePlayer = () => {
    Api.delete(Urls?.UPDATE_PLAYER + openDelete?.data?.id, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        toast?.success(res?.data?.message);
        setOpenDelete({ open: false, data: null });
        navigate("/admin/players");
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
      });
  };
  const handleClickAvatar = () => {
    setOpenChangePic(true);
  };
  const handleCloseEditPic = () => {
    setOpenChangePic(false);
    setFile(null);
    setError("");
  };
  const handleDrop = () => {
    setDrag(false);
  };
  const handleDragOver = () => {
    setDrag(true);
  };
  const handleDragLeave = () => {
    setDrag(false);
  };
  const SubmitUpdatePic = () => {
    Api.post(
      Urls?.UPDATE_AVATAR_PLAYER + player?.id + "/avatar",
      {
        file: file,
      },
      configsPic
    )
      .then((res) => {
        setProgressUpdatePic(0);
        setOpenChangePic(false);
        setFile(null);
        setChangePlayer(!changePlayer);
      })
      .catch((err) => {
        console.log(err);
        setProgressUpdatePic(0);
      });
  };
  const handleSelectResponse = (index, id) => {
    setQuestions((prevQuestions) => {
      let array = [...prevQuestions];
      let object = array[index];
      array.splice(index, 1);
      object = { ...object, response: id };
      if (index === questions?.length - 1) array.push(object);
      else array.splice(index, 0, object);
      return array;
    });
  };
  const handleClickPrivate = () => {
    setselectedBookingType("private");
  };
  const handleClickProgram = () => {
    setselectedBookingType("program");
  };
  useEffect(() => {
    Api.get(Urls.GET_COACHES, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setCoaches(response?.data?.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);
  useEffect(() => {
    if (!privateLesson) {
      setPlannings([]);
      setPrivateLessonPacks([]);
      setPrivateLessonPackLessons([]);
      setPrivateLessonPack(null);
      setPlannings([]);
    } else {
      setPrivateLessonPacks(privateLesson?.private_lesson_nbr_players);
    }
  }, [privateLesson]);
  useEffect(() => {
    if (setPrivateLessonPack) {
      setPrivateLessonPackLessons(privateLessonPack?.private_lesson_packs);
    } else {
      setPrivateLessonPackLessons([]);
    }
  }, [privateLessonPack]);
  useEffect(() => {
    if (coach && privateLesson) {
      Api.get(Urls.GET_PLANNINGS, {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
        params: {
          coach_id: coach?.id,
          private_lesson_id: privateLesson?.id,
        },
      })
        .then((response) => {
          setPlannings(response?.data?.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  }, [coach, privateLesson]);
  const handleClickAwards = () => {
    const playerAward = player?.awards;
    setInitialValuesAwards({
      awards: playerAward ? playerAward : "",
    });
    setOpenEditAwards({ open: true, data: playerAward });
  };
  const submitEditAwards = (values) => {
    Api.patch(
      Urls?.UPDATE_PLAYER + player?.id,
      {
        awards: values?.awards,
      },
      {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setOpenEditAwards({ open: false, data: null });
        setChangePlayer(!changePlayer);
        toast?.success(res?.data?.message);
      })
      .catch((err) => {
        toast?.error(err?.response?.data?.message);
      });
  };
  useEffect(() => {
    // Obtenir la date d'aujourd'hui
    var aujourd_hui = new Date();

    // Soustraire deux ans
    aujourd_hui.setFullYear(aujourd_hui.getFullYear() - 2);

    // Obtenir les composants de la date
    var annee = aujourd_hui.getFullYear();
    var mois = aujourd_hui.getMonth() + 1; // Mois commence à 0, donc ajoutez 1
    var jour = aujourd_hui.getDate();

    // Formater la date au format "YYYY-MM-DD"
    var date_il_y_a_deux_ans = annee + "-" + (mois < 10 ? "0" : "") + mois + "-" + (jour < 10 ? "0" : "") + jour;
    console.log(date_il_y_a_deux_ans, "date il ya deux ans");
    setMaxDate(date_il_y_a_deux_ans);
  }, []);

  return (
    <>
      <ToastContainer />
      <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
        <Box>
          <BreadcrumComp first="Players" second={player?.first_name + " " + player?.last_name} />
        </Box>

        {/* content selector */}
        <Box display="flex" alignItems="center">
          {content.map((c, index) => (
            <CustomButton key={index} active={selectedContent === c ? true : false} onClick={() => setSelectedContent(c)} variant={selectedContent === c ? "primary_active" : "primary_inactive"} text={c} startIcon={infos} />
          ))}
        </Box>

        {/* player card header */}
        <ContentBox>
          <Box
            display="flex"
            sx={{
              flexDirection: { xs: "column", lg: "row", sm: "row", md: "row" },
            }}
            gap="26px"
          >
            <Box>
              <Avatar onClick={isHover ? handleClickAvatar : null} onMouseOver={() => setIsHover(true)} onMouseOut={() => setIsHover(false)} variant="square" sx={{ borderRadius: "6px", height: "100px", width: "100px", filter: `${isHover ? "brightness(0.5)" : "none"}`, transition: "0.5s" }}>
                {!player?.url_avatar && <PersonIcon sx={{ height: "100px", width: "100px" }} />}
                {player?.url_avatar && <img src={player?.url_avatar} alt="profile-pic" height="100px" width="100px" />}
                {isHover && <CameraAltIcon fontSize="large" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "black", transition: "0.5s" }} />}
              </Avatar>
            </Box>
            <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
              <Box
                display="flex"
                gap="6px"
                sx={{
                  flexDirection: {
                    xs: "column",
                    lg: "row",
                    md: "row",
                    sm: "row",
                  },
                  alignItems: {
                    xs: "start",
                    sm: "center",
                    md: "center",
                    lg: "center",
                  },
                }}
                justifyContent="space-between"
              >
                <Box display="flex" gap="10px" flexDirection="column">
                  <Typography fontSize="18px" lineHeight="24px" fontWeight="500" color="#4B465C">
                    {player?.first_name} {player?.last_name}
                  </Typography>
                  <Box display="flex" gap="5px">
                    <Box widht="150px" height="25px">
                      <Chip variant="primary" label={player?.program?.name} />
                    </Box>

                    <Box widht="150px" height="25px">
                      <Chip variant="primary" label={player?.level_name} />
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box display="flex" gap="6px" alignItems="start">
                      <img src={calendarIcon} alt="position" />
                      <Typography fontSize="15px" lineHeight="22px" fontWeight="400" color="#4B465C">
                        Added {formatDate(player?.created_at)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                {player && (
                  <Box>
                    {!player?.awards && (
                      <IconButton onClick={handleClickAwards}>
                        <img style={{ height: "35px", width: "auto" }} src={awardsIcon} alt="awards" />
                      </IconButton>
                    )}
                    {player?.awards === "bronze" && (
                      <IconButton onClick={handleClickAwards} sx={{ borderRadius: "30px", padding: "5px" }}>
                        <img src={iconBronze} alt="awards" />
                      </IconButton>
                    )}
                    {player?.awards === "gold" && (
                      <IconButton onClick={handleClickAwards} sx={{ borderRadius: "30px", padding: "5px" }}>
                        <img src={iconGold} alt="awards" />
                      </IconButton>
                    )}
                    {player?.awards === "silver" && (
                      <IconButton onClick={handleClickAwards} sx={{ borderRadius: "30px", padding: "5px" }}>
                        <img src={iconSilver} alt="awards" />
                      </IconButton>
                    )}
                  </Box>
                )}
                <Box display="flex" gap="10px" alignItems="center">
                  <Button
                    onClick={() => {
                      setOpenAddBooking(true);
                      setPlanning([]);
                    }}
                    variant="primary_active"
                  >
                    Add a booking
                  </Button>
                  <ButtonSquare onClick={() => handleClickEdit(player)} action="edit_outlined" />
                  <ButtonSquare onClick={() => setOpenDelete({ open: true, data: player })} action="delete_red" />
                </Box>
              </Box>
            </Box>
          </Box>

          <Modal open={openAddBooking} onClose={() => setOpenAddBooking(false)}>
            <Box sx={modalStyleVertical}>
              <Box>
                <Box marginBottom="26px" alignItems="center" display="flex" justifyContent="space-between">
                  <Box>
                    <Typography fontSize="18px" lineHeight="24px" fontWeight="500" color="#4B465C">
                      add a booking
                    </Typography>
                  </Box>
                  <ButtonSquare action="close" onClick={() => setOpenAddBooking(false)} />
                </Box>
                <Box display="flex" gap="4px" marginBottom="24px">
                  <Box>
                    <Button onClick={handleClickProgram} variant={selectedBookingType === "program" ? "primary_active" : "primary_inactive"}>
                      Program
                    </Button>
                  </Box>
                  <Box>
                    <Button onClick={handleClickPrivate} variant={selectedBookingType !== "program" ? "primary_active" : "primary_inactive"}>
                      Private lesson
                    </Button>
                  </Box>
                </Box>
                {selectedBookingType === "program" && (
                  <Formik onSubmit={handleSubmitCreateBooking} validationSchema={validationSchema} initialValues={initialValues} enableReinitialize={true}>
                    {({ errors, setFieldValue, values }) => (
                      <Form>
                        <Box display="flex" flexDirection="column" gap="16px">
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              Select term
                            </Typography>
                            <Autocomplete
                              options={terms}
                              getOptionLabel={(option) => option.name}
                              value={values?.term}
                              isOptionEqualToValue={(option, value) => option?.id === value?.id}
                              renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Term" />}
                              onChange={(event, newValue) => {
                                setFieldValue("term", newValue);
                                setSelectedTermId(newValue ? newValue.id : null);
                              }}
                            />
                            {errors?.term && <FormikErrorText>{errors?.term?.id}</FormikErrorText>}
                          </Box>
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              Program
                            </Typography>

                            <Autocomplete
                              sx={{ minWidth: "300px" }}
                              options={programs}
                              value={values?.program ? values?.program : null}
                              getOptionLabel={(e) => e.name}
                              isOptionEqualToValue={(option, value) => option?.id === value?.id}
                              renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select program" />}
                              onChange={(event, value) => {
                                if (value !== null) {
                                  //console.log("selecting facture state value", value);
                                  //console.log("selecting facture state value", value.id);
                                  setFieldValue("program", value);
                                  setProgram(value);
                                  //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                } else {
                                  setFieldValue("program", null);
                                  setProgram(null);
                                }
                              }}
                            />
                            {errors?.program && <FormikErrorText>{errors?.program?.id}</FormikErrorText>}
                          </Box>

                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              Level
                            </Typography>
                            <Autocomplete
                              onChange={(event, value) => {
                                if (value !== null) {
                                  //console.log("selecting facture state value", value);
                                  //console.log("selecting facture state value", value.id);
                                  setFieldValue("level", value);
                                  setLevel(value);
                                  //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                } else {
                                  setFieldValue("level", null);
                                }
                              }}
                              sx={{ minWidth: "300px" }}
                              options={levels}
                              value={values?.level ? values?.level : null}
                              getOptionLabel={(e) => e.name}
                              isOptionEqualToValue={(option, value) => option?.id === value.id}
                              renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select level" />}
                            />
                            {errors?.level && <FormikErrorText>{errors?.level?.id}</FormikErrorText>}
                          </Box>
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              Duration
                            </Typography>
                            <Autocomplete
                              sx={{ minWidth: "300px" }}
                              options={durations}
                              value={values?.duration ? values?.duration : null}
                              getOptionLabel={(e) => e?.duration?.toString()}
                              isOptionEqualToValue={(option, value) => option?.id === value?.id}
                              renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select duration" />}
                              onChange={(event, value) => {
                                if (value !== null) {
                                  setFieldValue("duration", value);
                                  setDuration(value);
                                } else {
                                  setFieldValue("duration", null);
                                  setDuration(null);
                                }
                              }}
                            />
                            {errors?.duration && <FormikErrorText>{errors?.duration?.duration}</FormikErrorText>}
                          </Box>
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              Frequency
                            </Typography>
                            <Autocomplete
                              sx={{ minWidth: "300px" }}
                              options={frequencies}
                              value={values?.frequency ? values?.frequency : null}
                              getOptionLabel={(e) => e?.frequency_per_week?.toString()}
                              isOptionEqualToValue={(option, value) => option?.id === value?.id}
                              renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select duration" />}
                              onChange={(event, value) => {
                                if (value !== null) {
                                  setFieldValue("frequency", value);
                                } else {
                                  setFieldValue("frequency", null);
                                }
                              }}
                            />
                            {errors?.frequency && <FormikErrorText>{errors?.frequency?.id}</FormikErrorText>}
                          </Box>
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              Plannings
                            </Typography>
                            <Autocomplete
                              sx={{ minWidth: "300px" }}
                              options={plannings ? plannings : []}
                              multiple
                              value={[]}
                              blurOnSelect={true}
                              getOptionLabel={(e) => e?.day_name + " " + e?.start_time}
                              renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select duration" />}
                              onChange={(event, value) => {
                                setErrorPlanning("");
                                const exist = planning?.filter((select) => select.id === value[0].id);
                                if (!exist[0]) {
                                  setPlanning(planning?.concat(value));
                                }
                                setFieldValue("plannings", planning);
                              }}
                            />
                            {errorPlanning && <FormikErrorText>{errorPlanning}</FormikErrorText>}
                            <Box display="flex" marginTop="16px" flexDirection="column" gap="16px">
                              {planning?.map((plan) => (
                                <Box display="flex" alignItems="center" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
                                  <Typography fontWeight="600">{plan?.day_name} </Typography>
                                  <Typography fontWeight="600">-</Typography>
                                  <Typography fontWeight="600">{plan?.start_time} </Typography>
                                  <Typography fontWeight="600">-</Typography>

                                  <Typography fontWeight="600">{plan?.end_time}</Typography>
                                  <Typography fontWeight="600">-</Typography>

                                  <ButtonSquare
                                    action="delete_red"
                                    onClick={() => {
                                      let newArray = planning?.filter((select) => select.id !== plan?.id);
                                      setPlanning(newArray);
                                      setFieldValue("plannings", newArray);
                                    }}
                                  />
                                </Box>
                              ))}
                            </Box>
                          </Box>
                          <Box display="flex" gap="16px">
                            <Button type="submit" variant="primary_active">
                              Add
                            </Button>
                            <Button
                              onClick={() => {
                                setOpenAddBooking(false);
                              }}
                              type="submit"
                              variant="primary_inactive"
                            >
                              Cancel
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                )}
                {selectedBookingType === "private" && (
                  <Formik onSubmit={createBookingPrivate} validationSchema={validationSchemaPrivate} initialValues={initialValuesPrivate} enableReinitialize={true}>
                    {({ errors, setFieldValue, values }) => (
                      <Form>
                        <Box display="flex" flexDirection="column" gap="16px">
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              Private lesson
                            </Typography>
                            <Autocomplete
                              sx={{ minWidth: "300px" }}
                              options={programs}
                              value={values?.privateLesson ? values?.privateLesson : null}
                              getOptionLabel={(e) => e.duration + "min"}
                              isOptionEqualToValue={(option, value) => option?.id === value?.id}
                              renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select program" />}
                              onChange={(event, value) => {
                                if (value !== null) {
                                  setFieldValue("privateLesson", value);
                                  setPrivateLesson(value);
                                } else {
                                  setFieldValue("privateLesson", null);
                                  setPrivateLesson(null);
                                }
                              }}
                            />
                            {errors?.privateLesson && <FormikErrorText>{errors?.privateLesson?.id}</FormikErrorText>}
                          </Box>

                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              Coach
                            </Typography>
                            <Autocomplete
                              onChange={(event, value) => {
                                if (value !== null) {
                                  //console.log("selecting facture state value", value);
                                  //console.log("selecting facture state value", value.id);
                                  setFieldValue("coach_id", value);
                                  setCoach(value);
                                  //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                                } else {
                                  setFieldValue("coach_id", null);
                                }
                              }}
                              sx={{ minWidth: "300px" }}
                              options={coaches}
                              value={values?.coach_id ? values?.coach_id : null}
                              getOptionLabel={(e) => e.first_name + " " + e.last_name}
                              isOptionEqualToValue={(option, value) => option?.id === value.id}
                              renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select coach" />}
                            />
                            {errors?.coach_id && <FormikErrorText>{errors?.coach_id?.id}</FormikErrorText>}
                          </Box>
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              Number players
                            </Typography>
                            <Autocomplete
                              sx={{ minWidth: "300px" }}
                              options={privateLessonPacks}
                              value={values?.private_lesson_pack ? values?.private_lesson_pack : null}
                              getOptionLabel={(e) => e?.nbr_players}
                              isOptionEqualToValue={(option, value) => option?.id === value?.id}
                              renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select duration" />}
                              onChange={(event, value) => {
                                if (value !== null) {
                                  setFieldValue("private_lesson_pack", value);
                                  setPrivateLessonPack(value);
                                } else {
                                  setFieldValue("private_lesson_pack", null);
                                }
                              }}
                            />
                            {errors?.private_lesson_pack && <FormikErrorText>{errors?.private_lesson_pack?.id}</FormikErrorText>}
                          </Box>
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              private lessons pack
                            </Typography>
                            <Autocomplete
                              sx={{ minWidth: "300px" }}
                              options={privateLessonPackLessons}
                              value={values?.lessons_id ? values?.lessons_id : null}
                              getOptionLabel={(e) => e?.nbr_lessons + " lessons"}
                              isOptionEqualToValue={(option, value) => option?.id === value?.id}
                              renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select duration" />}
                              onChange={(event, value) => {
                                if (value !== null) {
                                  setFieldValue("lessons_id", value);
                                } else {
                                  setFieldValue("lessons_id", null);
                                }
                              }}
                            />
                            {errors?.lessons_id && <FormikErrorText>{errors?.lessons_id?.id}</FormikErrorText>}
                          </Box>
                          <Box>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              Plannings
                            </Typography>
                            <Autocomplete
                              sx={{ minWidth: "300px" }}
                              options={plannings ? plannings : []}
                              multiple
                              value={[]}
                              blurOnSelect={true}
                              getOptionLabel={(e) => e?.start_datetime}
                              renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select duration" />}
                              onChange={(event, value) => {
                                setErrorPlanning("");
                                const exist = planning?.filter((select) => select.id === value[0].id);
                                if (!exist[0]) {
                                  setPlanning(planning?.concat(value));
                                }
                                setFieldValue("plannings", planning);
                              }}
                            />
                            {errorPlanning && <FormikErrorText>{errorPlanning}</FormikErrorText>}
                            <Box display="flex" marginTop="16px" flexDirection="column" gap="16px">
                              {planning?.map((plan) => (
                                <Box display="flex" alignItems="center" flexDirection="row" flexWrap="wrap" gap="5px">
                                  <Typography fontWeight="600">{moment(plan?.start_datetime).format("dddd")} </Typography>
                                  <Typography fontWeight="600">-</Typography>
                                  <Typography fontWeight="600">{moment(plan?.start_datetime).format("MMMM DD yyyy HH:mm A")} </Typography>
                                  <Typography fontWeight="600">-</Typography>

                                  <Typography fontWeight="600">{moment(plan?.end_datetime).format("MMMM DD yyyy HH:mm A")}</Typography>

                                  <ButtonSquare
                                    action="delete_red"
                                    onClick={() => {
                                      let newArray = planning?.filter((select) => select.id !== plan?.id);
                                      setPlanning(newArray);
                                      setFieldValue("plannings", newArray);
                                    }}
                                  />
                                </Box>
                              ))}
                            </Box>
                          </Box>
                          <Box display="flex" gap="16px">
                            <Button type="submit" variant="primary_active">
                              Add
                            </Button>
                            <Button onClick={() => setOpenAddBooking(false)} type="submit" variant="primary_inactive">
                              Cancel
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                )}
              </Box>
            </Box>
          </Modal>
          <Modal open={openEdit?.open} onClose={() => setOpenEdit({ open: false, data: null })}>
            <Box sx={modalStyleOverflow}>
              <Box position="absolute" top="2px" right="2px">
                <ButtonSquare action="close" onClick={() => setOpenEdit({ open: false, data: null })} />
              </Box>
              <Box>
                <Formik onSubmit={(values) => handleUpdatePlayer(values)} validationSchema={validationSchemaPlayer} initialValues={initialValuesPlayer} enableReinitialize={true}>
                  {({ errors, setFieldValue, values }) => (
                    <Form>
                      <Box marginBottom="26px">
                        <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                          Update player
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection="column" gap="16px">
                        <Box display="flex" sx={{ gap: { xs: "8px", sm: "8px", md: "16px", lg: "26px" } }}>
                          <Box flex={1}>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              First name
                            </Typography>
                            <Field style={{ width: "100%" }} name="first_name" type="input" id="first_name" as={TextField} autoComplete="off" placeholder="John" />
                            <ErrorMessage component={FormikErrorText} name="first_name" />
                          </Box>
                          <Box flex={1}>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              Last name
                            </Typography>
                            <Field style={{ width: "100%" }} name="last_name" type="input" id="last_name" as={TextField} autoComplete="off" placeholder="Doe" />
                            <ErrorMessage component={FormikErrorText} name="last_name" />
                          </Box>
                        </Box>

                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            Date of birth{" "}
                          </Typography>
                          <Box width="100%">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                inputFormat="dd-MM-yyyy"
                                maxDate={maxDate}
                                value={values?.date_of_birth}
                                onChange={(e) => {
                                  setFieldValue("date_of_birth", moment(e).format("yyyy-MM-DD"));
                                  setDateOfBirth(e);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          </Box>
                          <ErrorMessage component={FormikErrorText} name="date_of_birth" />
                        </Box>
                        <Box>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            Questions :
                          </Typography>
                          <Box display="flex" flexDirection="column" gap="10px">
                            {questions?.map((question, index) => (
                              <Box key={question?.id} display="flex" flexDirection="column" gap="5px">
                                <Typography marginLeft="20px" fontSize="15px" fontWeight="500" color="#4B465C">
                                  - {question?.value}
                                </Typography>
                                {question?.answers?.map((ans) => (
                                  <Box onClick={() => handleSelectResponse(index, ans?.id)} sx={question?.response !== ans?.id ? { borderRadius: "15px", border: "1px solid lightgray", textAlign: "center", cursor: "pointer", padding: "10px 10px" } : { borderRadius: "15px", border: "1px solid lightgray", textAlign: "center", cursor: "pointer", padding: "10px 10px", backgroundColor: `${colors.main}` }} key={ans?.id}>
                                    {ans.value}
                                  </Box>
                                ))}
                              </Box>
                            ))}
                          </Box>
                        </Box>
                        <Box textAlign="center">{errorResponses && <FormikErrorText>Please answer all the questions</FormikErrorText>}</Box>
                        <Box marginTop="10px" justifyContent="center" display="flex" gap="16px">
                          <Button type="submit" variant="primary_active">
                            update
                          </Button>
                          <Button onClick={() => setOpenEdit({ open: false, data: null })} variant="primary_inactive">
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Modal>
          <Modal open={openDelete?.open} onClose={() => setOpenDelete({ open: false, data: null })}>
            <Box sx={modalStyleOverflow}>
              <Box position="absolute" top="2px" right="2px">
                <ButtonSquare action="close" onClick={() => setOpenDelete({ open: false, data: null })} />
              </Box>
              <Box marginBottom="26px">
                <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                  Delete player
                </Typography>
              </Box>
              <Box>
                <Typography textAlign="center" fontSize="22px" lineHeight="32px" fontWeight="500" color="#4B465C">
                  Are you sure you want to delete this player (id : {openDelete?.data?.id})?
                </Typography>
              </Box>
              <Box marginTop="16px" justifyContent="center" display="flex" gap="16px">
                <Button onClick={handleDeletePlayer} variant="primary_active">
                  Delete
                </Button>
                <Button onClick={() => setOpenDelete({ open: false, data: null })} variant="primary_inactive">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal open={openChangePic} onClose={handleCloseEditPic}>
            <Box sx={modalStyleOverflow}>
              <Box position="absolute" top="2px" right="2px">
                <ButtonSquare action="close" onClick={handleCloseEditPic} />
              </Box>
              <Box marginBottom="26px">
                <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                  Update profile picture
                </Typography>
              </Box>
              <Box sx={{ cursor: "pointer", minWidth: "200px" }} position="relative">
                <input onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} accept="image/jpeg , image/png , image/jpg" onChange={(e) => VerifyFile(e)} style={{ opacity: "0", zIndex: "0", height: "100%", width: "100%", position: "absolute" }} id="avatar-input" type="file" />
                <Box justifyContent="center" display="flex">
                  <Box sx={{ width: "100%", transition: "0.2s", background: `${drag ? "rgba(75, 70, 92, 0.1)" : "white"}` }} gap="10px" display="flex" flexDirection="column" alignItems="center" padding="40px 50px" border="1px dashed #DBDADE">
                    <img src={uploadIcon} alt="upload" />
                    <Typography textAlign="center" fontSize="22px" lineHeight="30px" fontWeight="500" color="#4B465C">
                      {!file && "Drop files here or click to upload"}
                      {file && file.name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" marginTop="16px" justifyContent="center">
                {error && <FormikErrorText>{error}</FormikErrorText>}
              </Box>
              {progressUpdatePic !== 0 && (
                <Box marginTop="16px" display="flex" gap="16px" alignItems="center" justifyContent="center">
                  <CircularProgress />
                  <Typography fontSize="22px" lineHeight="30px" fontWeight="500" color="#4B465C">
                    {progressUpdatePic}%
                  </Typography>
                </Box>
              )}
              <Box marginTop="26px" display="flex" justifyContent="center" gap="16px">
                <Button onClick={SubmitUpdatePic} disabled={!isFileVerified} variant="primary_active">
                  Update picture
                </Button>
                <Button variant="primary_inactive" onClick={handleCloseEditPic}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal open={openEditAwards?.open} onClose={() => setOpenEditAwards({ open: false, data: null })}>
            <Box sx={modalStyleOverflow}>
              <Box position="absolute" top="2px" right="2px">
                <ButtonSquare action="close" onClick={() => setOpenEditAwards({ open: false, data: null })} />
              </Box>
              <Box>
                <Formik onSubmit={(values) => submitEditAwards(values)} validationSchema={validationSchemaAwards} initialValues={initialValuesAwards} enableReinitialize={true}>
                  {({ errors, setFieldValue, values }) => (
                    <Form>
                      <Box marginBottom="26px">
                        <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                          Update awards
                        </Typography>
                      </Box>
                      <Box display="flex" flexDirection="column" gap="16px">
                        <Box display="flex" sx={{ gap: { xs: "8px", sm: "8px", md: "16px", lg: "26px" } }}>
                          <Box flex={1}>
                            <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                              awards
                            </Typography>
                            <Autocomplete
                              sx={{ minWidth: "200px" }}
                              options={["Bronze", "Silver", "Gold"]}
                              value={values?.awards}
                              getOptionLabel={(e) => e}
                              isOptionEqualToValue={(option, value) => option.toLowerCase() === value}
                              onChange={(event, value) => {
                                if (value !== null) {
                                  setFieldValue("awards", value?.toLowerCase());
                                } else {
                                  setFieldValue("awards", null);
                                }
                              }}
                              renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Select levels" />}
                            />{" "}
                            <ErrorMessage component={FormikErrorText} name="awards" />
                          </Box>
                        </Box>
                        <Box marginTop="10px" display="flex" gap="16px">
                          <Button type="submit" variant="primary_active">
                            update
                          </Button>
                          <Button onClick={() => setOpenEditAwards({ open: false, data: null })} variant="primary_inactive">
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Modal>
        </ContentBox>

        {/* content */}
        {contentSelector(selectedContent)}
      </Box>
    </>
  );
}

export default PlayerDetails;
