import React, { useEffect, useState } from "react";
import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import ContentBox from "../../components/ui/ContentBox";
import BookingIcon from "../../imgs/booking.svg";
import ClientIcon from "../../imgs/users-icon-blue.svg";
import CoachIcon from "../../imgs/coach-icon.svg";
import { useAuthContext } from "../../hooks/useAuthContext";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useNavigate } from "react-router-dom";

function Statistics() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [booking, setBooking] = useState(0);
  const [players, setPlayers] = useState(0);
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    Api.get(Urls.GET_BOOKING_TOTAL, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res);
        setBooking(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    Api.get(Urls.GET_TOTAL_CLIENTS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res);
        setClients(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    Api.get(Urls.GET_TOTAL_PLAYERS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        console.log(res);
        setPlayers(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Box height="100%">
      <ContentBox height="100%">
        <Box display="flex" flexDirection="column" gap="51px">
          <Box>
            <Typography fontSize="18px" fontWeight="500" lineHeight="22px" color="#4B465C">
              Statistics
            </Typography>
          </Box>
          {loading && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          {!loading && (
            <Box display="flex" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, alignItems: { xs: "start", sm: "start", md: "center", lg: "center" } }}>
              <Box display="flex" gap="16px" flex={1} alignItems="center">
                <Avatar sx={{ borderRadius: "30px", backgroundColor: "rgba(159, 49, 186, 0.16)", padding: "9px" }}>
                  <img style={{ margin: "9px" }} src={BookingIcon} alt="booking" />
                </Avatar>
                <Box>
                  <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                    {booking}
                  </Typography>
                  <Typography fontSize="13px" lineHeight="28px" fontWeight="400" color="#4B465C">
                    Total booking
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/admin/users")} display="flex" gap="16px" flex={1} alignItems="center">
                <Avatar sx={{ borderRadius: "30px", backgroundColor: "rgba(0, 207, 232, 0.16)", padding: "9px" }}>
                  <img style={{ margin: "9px" }} src={ClientIcon} alt="booking" />
                </Avatar>
                <Box>
                  <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                    {clients}
                  </Typography>
                  <Typography fontSize="13px" lineHeight="28px" fontWeight="400" color="#4B465C">
                    Clients
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ cursor: "pointer" }} onClick={() => navigate("/admin/players")} display="flex" gap="16px" flex={1} alignItems="center">
                <Avatar sx={{ borderRadius: "30px", backgroundColor: "rgba(234, 84, 85, 0.16)", padding: "9px" }}>
                  <img style={{ margin: "9px" }} src={CoachIcon} alt="booking" />
                </Avatar>
                <Box>
                  <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                    {players}
                  </Typography>
                  <Typography fontSize="13px" lineHeight="28px" fontWeight="400" color="#4B465C">
                    Players
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </ContentBox>
    </Box>
  );
}

export default Statistics;
