import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { cardInfos } from "../components/data/AdminProfileInfos";
import UserCard from "../components/profileComponents/UserCard";
import UserInfos from "../components/profileComponents/UserInfos";
import { useAuthContext } from "../hooks/useAuthContext";
import Api from "../api/Api";
import { Urls } from "../api/Urls";
function AdminProfile({ userData, updateInfos, setUpdateInfos }) {
  const { user } = useAuthContext();
  const user_id = user?.data?.id;
  useEffect(() => {
    document.title = "BTA admin - Profile";
  }, []);
  return (
    <>
      {!userData && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {userData && (
        <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
          <Box>
            <Typography fontSize="22px" fontWeight="500" lineHeight="30px" color="#4B465C">
              User profile
            </Typography>
          </Box>
          <UserCard
            id={user_id}
            edit={true}
            updateInfos={updateInfos}
            setUpdateInfos={setUpdateInfos}
            birthday={userData?.date_of_birth}
            url_avatar={userData?.url_avatar}
            type="super_admin"
            fullName={userData?.first_name + " " + userData?.last_name}
            joined={userData?.joined}
            contact={userData?.phone_number}
            email={userData?.email}
          />
          <UserInfos type="super-admin" fullName={userData?.first_name + " " + userData?.last_name} contact={userData?.phone_number} email={userData?.email} />
        </Box>
      )}
    </>
  );
}

export default AdminProfile;
