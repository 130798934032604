export const level_columns = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "LEVEL NAME",
    width: "70%",
    align: "left",
  },
  {
    name: "ACTION",
    width: "25%",
    align: "left",
  },
];
