export const categories_columns = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "CATEGORIES",
    width: "70%",
    align: "left",
  },
  {
    name: "TOTAL PRODUCTS",
    width: "25%",
    align: "left",
  },
  {
    name: "ACTIONS",
    width: "15%",
    align: "left",
  },
];
