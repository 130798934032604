import React, { useState } from "react";
import TextField from "@mui/material/TextField";

const VariantsTextField = ({ form, field, ...props }) => {
  const { name } = field;
  const { setFieldValue } = form;

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    const formattedValue = event.target.value.replace(/ /g, "").replace(/[^0-9]/g, "");
    setInputValue(formattedValue);
    setFieldValue(name, formattedValue);
  };

  return (
    <TextField
      {...props}
      value={inputValue}
      onChange={handleInputChange}
      inputProps={{
        pattern: "[0-9]*",
      }}
    />
  );
};

export default VariantsTextField;
