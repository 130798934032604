import { Autocomplete, Box, Button, TextField, Typography, Chip, CircularProgress, Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import styled from "@emotion/styled";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ContentBox from "../../../components/ui/ContentBox";

import UserType from "../../../components/ui/UserType";
import TableShowUser from "../../../components/ui/TableShowUser";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import { useNavigate } from "react-router-dom";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { purchases_columns } from "../../../components/data/purchaseColumns";
import { purchases_rows } from "../../../components/data/purchaseRows";
import moment from "moment";
import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormikErrorText from "../../../components/ui/FormikErrorText";

import BreadcrumComp from "../../../components/ui/BreadcrumComp";
import { modalStyleOverflow } from "../../../theme/ModalStyle";

function Purchases() {
  useEffect(() => {
    document.title = "BTA admin - Store / Purchases";
  }, []);
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const navigate = useNavigate();
  const [change, setChange] = useState(false);
  const [openSetPaid, setOpenPaid] = useState({ open: false, data: null });
  const initialValuesPaid = {
    discount: 0,
  };
  const validationSchemaPaid = yup.object({
    discount: yup?.number("Invalid value").min(0, "invalid value").required("This field is required"),
  });
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");

  const perPage = "?perPage=10";

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "white",
      paddingTop: "8px",
      paddingBottom: "8px",
      letterSpacing: "1px",
      color: `#4B465C`,
      fontSize: 13,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      background: "white",
      fontSize: 14,
      color: `#666D83`,
      fontWeight: "500",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    ":hover": {
      filter: "brightness(0.95)",
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "0px",
  });
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const [orders, setOrders] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Api.get(Urls.GET_ORDERS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(search && { search: search }),
        ...(status && { status: status }),
      },
    })
      .then((res) => {
        setOrders(res?.data?.data);
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, [search, status, change]);
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log("RESULTAT MATCHED offres DATA", res?.data);
        //console.log("RESPONES GHERBI", res);
        setData(res?.data);
        setOrders(res.data?.data);
        //console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        //console.log(err);
        setOrders([]);
      });
  };
  const markAsPayed = (values) => {
    Api.patch(
      Urls.PATCH_ORDER + openSetPaid?.data,
      {
        status: "PAID",
        discount: values?.discount,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success(res?.data?.message);
        setChange(!change);
        setOpenPaid({ open: false, data: null });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const markAsCanceled = (id) => {
    Api.patch(
      Urls.PATCH_ORDER + id,
      {
        status: "CANCELED",
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast?.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <>
      <ToastContainer />
      <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
        <Box>
          <BreadcrumComp first="Store" second="Purchases" />
        </Box>
        <ContentBox>
          <Box display="flex" flexDirection="column" gap="16px">
            <Box>
              <Typography fontSize="18px" fontWeight="500" lineHeight="24px" color="#4B465C">
                Purchases
              </Typography>
            </Box>
            <Box marginTop="8px" display="flex" flexWrap="wrap" gap="16px" flexDirection="row">
              <TextField value={search} onChange={handleSearchChange} type="number" placeholder="Search invoice id" />
              <Autocomplete
                sx={{ minWidth: "250px" }}
                options={["PENDING", "CANCELED", "PAID"]}
                getOptionLabel={(e) => e}
                onChange={(event, value) => {
                  if (value !== null) {
                    //console.log("selecting facture state value", value);
                    //console.log("selecting facture state value", value.id);
                    setStatus(value);
                    //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                  } else {
                    setStatus(null);
                  }
                }}
                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Status" />}
              />
            </Box>
            {loading && (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            )}
            {!loading && (
              <TableContainer sx={{ borderRadius: "0px", border: `1px solid #DBDADE` }}>
                <StyledTable aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      {purchases_columns.map((col, index) => (
                        <StyledTableCell key={index} width={col.width} align={col.align}>
                          {col.name}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders?.map((purchase) => (
                      <StyledTableRow key={purchase?.id}>
                        <StyledTableCell onClick={() => navigate(`/admin/purchases/${purchase.id}`)} align="left">
                          <Typography fontWeight="400" color="#3890E2" fontSize="15px">
                            #{purchase?.id}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell onClick={() => navigate(`/admin/purchases/${purchase.id}`)} align="left">
                          <Box>
                            <TableShowUser url_photo={purchase?.user_url_avatar} email={purchase?.user_email} full_name={purchase?.user_first_name + " " + purchase?.user_last_name} />
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell onClick={() => navigate(`/admin/purchases/${purchase.id}`)} align="left">
                          {parseFloat(purchase?.total).toLocaleString("fr-FR")} BHD
                        </StyledTableCell>
                        <StyledTableCell onClick={() => navigate(`/admin/purchases/${purchase.id}`)} align="left">
                          {moment(purchase?.created_at).format("DD MMMM YYYY")}
                        </StyledTableCell>
                        <StyledTableCell onClick={() => navigate(`/admin/purchases/${purchase.id}`)} align="left">
                          {purchase?.updated_at ? moment(purchase?.updated_at).format("DD MMMM YYYY") : "-"}
                        </StyledTableCell>
                        <StyledTableCell onClick={() => navigate(`/admin/purchases/${purchase.id}`)} align="center">
                          <Box>
                            {(purchase?.status === "PAID" || purchase?.status === "CANCELED") && <Chip label={purchase?.status === "PAID" ? "PAID" : "CANCELED"} sx={{ backgroundColor: `${purchase?.status === "PAID" ? "rgba(40, 199, 111, 0.16)" : "rgba(234, 84, 85, 0.16)"}`, color: `${purchase?.status === "PAID" ? "rgba(40, 199, 111, 1)" : "rgba(234, 84, 85, 1)"}`, borderRadius: "8px" }} />}
                            {purchase?.status === "PENDING" && <Chip label={"PENDING"} sx={{ backgroundColor: "rgba(255, 213, 128 , 0.5)", color: "orange", borderRadius: "8px" }} />}
                          </Box>{" "}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          <Box justifyContent="left" justifyContent="center" alignItems="center" display="flex" gap="5px">
                            {purchase?.status === "PENDING" && (
                              <Button onClick={() => setOpenPaid({ open: true, data: purchase?.id })} variant="primary_active">
                                Paid
                              </Button>
                            )}
                            {purchase?.status !== "CANCELED" && purchase?.status !== "PAID" && (
                              <Button variant="primary_inactive" onClick={() => markAsCanceled(purchase?.id)}>
                                Canceled
                              </Button>
                            )}
                          </Box>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            )}
            {orders?.length === 0 && !loading && <Typography textAlign="center">No orders</Typography>}
            {orders?.length > 0 && (
              <Box justifyContent="flex-end" display="flex" gap="4px">
                {data?.links?.prev && (
                  <ButtonSquare
                    onClick={() => {
                      handlePagination(data?.links?.prev);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action="Previous"
                  />
                )}
                <Box display="flex" gap="4px" justifyContent="center">
                  {data?.meta?.links?.map((link) => (
                    <ButtonSquare
                      onClick={() => {
                        handlePagination(link.url);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      action={link?.active ? "number-active" : "number"}
                      number={link.label}
                    />
                  ))}
                </Box>
                {data?.links?.next && (
                  <ButtonSquare
                    onClick={() => {
                      handlePagination(data?.links?.next);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }}
                    action="Next"
                  />
                )}
              </Box>
            )}
          </Box>
        </ContentBox>
        <Modal open={openSetPaid?.open} onClose={() => setOpenPaid({ open: false, data: null })}>
          <Box sx={modalStyleOverflow}>
            <Box position="absolute" top="2px" right="2px">
              <ButtonSquare action="close" onClick={() => setOpenPaid({ open: false, data: null })} />
            </Box>
            <Box>
              <Formik onSubmit={(values) => markAsPayed(values)} validationSchema={validationSchemaPaid} initialValues={initialValuesPaid} enableReinitialize={true}>
                {({ errors, setFieldValue, values }) => (
                  <Form>
                    <Box marginBottom="26px">
                      <Typography textAlign="center" fontSize="26px" lineHeight="36px" fontWeight="500" color="#4B465C">
                        Mark as paid
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" gap="16px">
                      <Box display="flex" sx={{ gap: { xs: "8px", sm: "8px", md: "16px", lg: "26px" } }}>
                        <Box flex={1}>
                          <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                            Discount
                          </Typography>
                          <Field style={{ width: "100%" }} name="discount" type="number" id="level_name" as={TextField} autoComplete="off" placeholder="Discount..." />
                          <ErrorMessage component={FormikErrorText} name="discount" />
                        </Box>
                      </Box>
                      <Box marginTop="10px" display="flex" gap="16px">
                        <Button type="submit" variant="primary_active">
                          Mark as PAID
                        </Button>
                        <Button onClick={() => setOpenPaid({ open: false, data: null })} variant="primary_inactive">
                          Cancel
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
}

export default Purchases;
