import React, { useEffect, useState } from "react";
import { Box, Avatar, Typography, Chip, CircularProgress } from "@mui/material";
import calendarIcon from "../../../imgs/calendarIcon.svg";
import CustomButton from "../../../components/ui/CustomButton";
import BreadcrumComp from "../../../components/ui/BreadcrumComp";
import infos from "../../../imgs/infos.svg";

import CoachInformations from "./CoachInformations";
import Calendar from "./Calendar";
import { cardInfos } from "../../../components/data/AdminProfileInfos";
import UserCard from "../../../components/profileComponents/UserCard";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Payslips from "./Payslips";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";

function CoachDetails() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const content = ["Informations", "Calendar", "Payslips"];
  const [selectedContent, setSelectedContent] = useState(content[0]);
  const [updateInfos, setUpdateInfos] = useState(false);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);

  const { id } = useParams();
  const contentSelector = (content) => {
    switch (content) {
      case "Informations":
        return <CoachInformations userInfos={userData} id={id} />;
      case "Calendar":
        return <Calendar id={id} />;
      case "Payslips":
        return <Payslips id={id} />;
      default:
        return;
    }
  };
  useEffect(() => {
    Api.get(Urls?.GET_COACH_ID + id, {
      headers: { "content-type": "application", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setUserData(res?.data?.data);
        setLoading(false);
        console.log(res?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [updateInfos]);

  return (
    <>
      {!loading && (
        <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
          <Box>
            <BreadcrumComp first="Users" second={userData?.first_name + " " + userData?.last_name} />
          </Box>
          {/* content selector */}
          <Box display="flex" alignItems="center">
            {content.map((c, index) => (
              <CustomButton key={index} active={selectedContent === c ? true : false} onClick={() => setSelectedContent(c)} variant={selectedContent === c ? "primary_active" : "primary_inactive"} text={c} startIcon={c === "Informations" ? infos : calendarIcon} />
            ))}
          </Box>
          <UserCard updateInfos={updateInfos} biography={userData?.biography} setUpdateInfos={setUpdateInfos} edit={selectedContent === "Informations" ? true : false} type="coach" id={id} fullName={userData?.first_name + " " + userData?.last_name} joined={userData?.created_at?.split(" ")[0]} contact={userData?.phone_number} email={userData?.email} url_avatar={userData?.url_avatar} />

          {/* content */}
          {contentSelector(selectedContent)}
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default CoachDetails;
