import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";

export const profileMenuContent = [
  {
    caption: "Profile",
    link: "/admin/profile",
    icon: <AccountCircleOutlinedIcon htmlColor="#5d5a68" />,
  },
  {
    caption: "Sign out",
    link: "/login",
    icon: <ExitToAppOutlinedIcon htmlColor="#5d5a68" />,
  },
];
