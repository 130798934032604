import React, { useEffect, useState } from "react";
import { Box, Avatar, Typography, Chip, CircularProgress } from "@mui/material";
import cartIcon from "../../../imgs/cart.svg";
import CustomButton from "../../../components/ui/CustomButton";
import BreadcrumComp from "../../../components/ui/BreadcrumComp";
import infos from "../../../imgs/infos.svg";
import UserInformations from "./UserInformations";
import UserCard from "../../../components/profileComponents/UserCard";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import MembersIcon from "../../../imgs/users-icon.svg";
import DiamondIcon from "../../../imgs/diamand.svg";
import UserPurchases from "./UserPurchases";
import UserMembers from "./UserMembers";
import UserMemberships from "./UserMemberships";
import { ToastContainer } from "react-toastify";
import PlayerMemberships from "../../players/PlayerMemberships";

function UserDetails() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const content = ["Informations", "Members", "Purchases", "Memberships"];
  const [selectedContent, setSelectedContent] = useState(content[0]);
  const [updateInfos, setUpdateInfos] = useState(false);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    document.title = "BTA admin - Users";
  }, []);
  const { id } = useParams();
  const contentSelector = (content) => {
    switch (content) {
      case "Informations":
        return <UserInformations userInfos={userData} id={id} />;
      case "Members":
        return <UserMembers updateInfos={updateInfos} setUpdateInfos={setUpdateInfos} id={id} />;
      case "Purchases":
        return <UserPurchases id={id} />;
      case "Memberships":
        return userData?.player_id && !userData?.player_deleted_at ? (
          <PlayerMemberships id={userData?.player_id} />
        ) : (
          <Box marginTop="16px">
            <Typography sx={{ textAlign: "center" }}>No bookings</Typography>
          </Box>
        );
      default:
        return <UserInformations userInfos={userData} id={id} />;
    }
  };
  useEffect(() => {
    Api.get(Urls?.GET_USER + id, {
      headers: { "content-type": "application", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setUserData(res?.data?.data);
        setLoading(false);
        console.log(res?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [updateInfos]);

  return (
    <>
      {!loading && (
        <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
          <ToastContainer />
          <Box>
            <BreadcrumComp first="Users" second={userData?.first_name + " " + userData?.last_name} />
          </Box>
          {/* content selector */}
          <Box display="flex" alignItems="center" flexWrap="wrap">
            {content.map((c, index) => (
              <CustomButton key={index} active={selectedContent === c ? true : false} onClick={() => setSelectedContent(c)} variant={selectedContent === c ? "primary_active" : "primary_inactive"} text={c} startIcon={c === "Informations" ? infos : c === "Memberships" ? DiamondIcon : c === "Members" ? MembersIcon : cartIcon} />
            ))}
          </Box>
          <UserCard player_id={userData?.player_id} player_deleted_at={userData?.player_deleted_at} birthday={userData?.date_of_birth} gender={userData?.gender} updateInfos={updateInfos} setUpdateInfos={setUpdateInfos} edit={selectedContent === "Informations" ? true : false} type="user" id={id} bta_points={userData?.bta_points} fullName={userData?.first_name + " " + userData?.last_name} joined={userData?.created_at?.split(" ")[0]} contact={userData?.phone_number} email={userData?.email} url_avatar={userData?.url_avatar} />

          {/* content */}
          {contentSelector(selectedContent)}
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default UserDetails;
