import React from "react";
import { InputLabel, styled } from "@mui/material";

const ErrorText = styled(InputLabel)({
  // marginTop: "0.3rem",
  color: "#CC0000",
  fontSize: "12px",
});

function FormikErrorText({ children }) {
  return <ErrorText>{children}</ErrorText>;
}

export default FormikErrorText;
