export const terms_columns = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "TERM NAME",
    width: "25%",
    align: "left",
  },
  {
    name: "START DATE",
    width: "15%",
    align: "left",
  },
  {
    name: "END DATE",
    width: "15%",
    align: "left",
  },
  {
    name: "N° WEEKS",
    width: "15%",
    align: "left",
  },
  {
    name: "STATUS",
    width: "10%",
    align: "center",
  },
  {
    name: "ACTION",
    width: "15%",
    align: "center",
  },
];
