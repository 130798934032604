export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  borderRadius: "6px",
  boxShadow: 24,
  padding: "36px",
  minWidth: { xs: "300px", sm: "400px", md: "600px", lg: "700px" },
  maxHeight: "95vh",
  PointerEvent: "auto",
  zIndex: 0,
};
export const modalStyleVertical = {
  position: "absolute",
  top: "0",
  left: "100%",
  transform: "translate(-100%, 0%)",
  bgcolor: "white",
  height: "100vh",
  padding: "24px",
  minWidth: { xs: "300px", sm: "400px", md: "400px", lg: "400px" },
};
export const modalStyleOverflow = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  borderRadius: "6px",
  overflowY: "auto",
  boxShadow: 24,
  padding: "36px",
  minWidth: { xs: "300px", sm: "500px", md: "700px", lg: "800px" },
  maxHeight: "95vh",
  PointerEvent: "auto",
  zIndex: 0,
};
