import React from "react";
import { Box } from "@mui/material";

function ContentBox({ children, ...props }) {
  return (
    <Box
      {...props}
      sx={{
        "box-shadow": "0px 10px 20px 0px rgba(165, 163, 174, 0.20)",
      }}
      bgcolor="white"
      borderRadius="6px"
      p={3}
    >
      {children}
    </Box>
  );
}

export default ContentBox;
