import { createTheme } from "@mui/material/styles";
import { colors } from "./Colors";
import { enUS } from "@mui/material/locale";

//MUI CUSTOM THEME
export const theme = createTheme(
  {
    palette: {
      primary: {
        main: colors.main,
        secondary: colors.gray,
        white: "#FFFFF",
      },
      neutral: {
        main: "#FFFFF",
      },
    },
    typography: {
      fontFamily: ["Public sans"],
      button: {
        textTransform: "none",
      },
    },
    components: {
      MuiChip: {
        variants: [
          {
            props: {
              variant: "primary",
            },
            style: {
              color: "#a8aaae",
              fontWeight: "600",
              background: "#f1f1f2",
              borderRadius: "6px",
              height: "22px",
              "& .MuiChip-label": {
                padding: "5px 10px",
              },
            },
          },
          {
            props: {
              variant: "green",
            },
            style: {
              color: "#28C76F",
              fontWeight: "600",
              background: "#dcf6e8",
              borderRadius: "6px",
              height: "22px",
              "& .MuiChip-label": {
                padding: "5px 10px",
              },
            },
          },
          {
            props: {
              variant: "red",
            },
            style: {
              color: "#7C0000",
              fontWeight: "600",
              background: "#FAE6E6",
              borderRadius: "6px",
              height: "22px",
              "& .MuiChip-label": {
                padding: "5px 10px",
              },
            },
          },
        ],
      },
      MuiButton: {
        styleOverrides: {
          root: {
            minHeight: "10px",
            minWidth: "10px",
          },
        },
        variants: [
          {
            props: {
              variant: "primary_active",
            },
            style: {
              color: "white",
              fontSize: "15px",
              background: `${colors.main}`,
              borderRadius: "8px",
              padding: "10px 20px",
              "&:hover": {
                background: `${colors.main}`,
                filter: "brightness(0.95)",
              },
            },
          },
          {
            props: {
              variant: "primary_inactive",
            },
            style: {
              color: `${colors.gray}`,
              fontSize: "15px",
              background: `transparent`,
              borderRadius: "8px",
              padding: "10px 20px",
              "&:hover": {
                background: `transparent`,
                filter: "brightness(0.95)",
                boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.2)",
              },
            },
          },
          {
            props: {
              variant: "views_active",
            },
            style: {
              color: `#FF0101`,
              fontSize: "12px",
              fontWeight: "500",
              borderRadius: "0px",
              background: `rgba(255, 1, 1, 0.3)`,
              padding: "10px 20px",
              "&:hover": {
                background: `rgba(255, 1, 1,0.3)`,
              },
            },
          },
          {
            props: {
              variant: "views_inactive",
            },
            style: {
              color: "#FF0101",
              fontSize: "12px",
              borderRadius: "0px",
              fontWeight: "500",
              background: `#FFDBDB`,
              padding: "10px 20px",
              "&:hover": {
                background: `rgba(255, 1, 1, 0.3)`,
              },
            },
          },
          {
            props: {
              variant: "views_inactive_left_radius",
            },
            style: {
              color: "#FF0101",
              fontSize: "12px",
              fontWeight: "500",

              background: `#FFDBDB`,
              borderTopLeftRadius: "6px",
              borderBottomLeftRadius: "6px",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",

              padding: "10px 20px",
              "&:hover": {
                background: `rgba(255, 1, 1, 0.3)`,
              },
            },
          },
          {
            props: {
              variant: "views_inactive_right_radius",
            },
            style: {
              color: "#FF0101",
              fontSize: "12px",
              fontWeight: "500",

              background: `#FFDBDB`,
              borderTopRightRadius: "6px",
              borderBottomRightRadius: "6px",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              padding: "10px 20px",
              "&:hover": {
                background: `rgba(255, 1, 1, 0.3)`,
              },
            },
          },
          {
            props: {
              variant: "views_active_left_radius",
            },
            style: {
              color: `#FF0101`,
              fontSize: "12px",
              fontWeight: "500",

              borderTopLeftRadius: "6px",
              borderBottomLeftRadius: "6px",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              background: `rgba(255, 1, 1, 0.3)`,
              padding: "10px 20px",
              "&:hover": {
                background: `rgba(255, 1, 1, 0.3)`,
              },
            },
          },
          {
            props: {
              variant: "views_active_right_radius",
            },
            style: {
              color: `#FF0101`,
              fontWeight: "500",

              fontSize: "12px",
              borderTopRightRadius: "6px",
              borderBottomRightRadius: "6px",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              background: `rgba(255, 1, 1, 0.3)`,
              padding: "10px 20px",
              "&:hover": {
                background: `rgba(255, 1, 1, 0.3)`,
              },
            },
          },
          {
            props: {
              variant: "secondary_gray",
            },
            style: {
              color: `${colors.gray}`,
              fontSize: "15px",
              background: "#F1F1F2",
              borderRadius: "8px",
              padding: "10px 20px",
            },
          },
          {
            props: {
              variant: "secondary_close",
            },
            style: {
              color: `${colors.gray}`,
              fontSize: "15px",
              background: "#F1F1F2",
              borderRadius: "8px",
              padding: "10px 20px",
              "&:hover": {
                background: "#F1F1F2",
                filter: "brightness(0.95)",
              },
            },
          },
          {
            props: {
              variant: "secondary_red",
            },
            style: {
              color: `#EA5455`,
              fontSize: "15px",
              background: "#f5dce0",
              borderRadius: "8px",
              padding: "10px 20px",
              "&:hover": {
                background: `rgb(245, 220, 224, 0.7)`,
              },
            },
          },
        ],
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            "& .MuiSelect-select": {
              borderColor: "#DEDFE4",
              borderRadius: "6px",
              fontSize: "14px",
              height: "38px",

              lineHeight: "22.4px",
              fontWeight: "400",
              padding: "0px 10px",
            },
            "& .MuiSelect-standard": {
              borderColor: "#DEDFE4",
              height: "38px",
              borderRadius: "6px",
              fontSize: "14px",
              lineHeight: "22.4px",
              fontWeight: "400",
              padding: "0px 10px",
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            color: colors.tFieldGray,
            "& .MuiInputBase-root": {
              minHeight: "38px",
              padding: "0px 10px",
            },
            "& .MuiAutocomplete-input": {
              padding: "0px 10px !important",
            },
            "& .css-gqvsnj-MuiAutocomplete-root": {
              padding: "0px 10px",
            },
            "& .css-wq7oss-MuiAutocomplete-root": {
              padding: "0px 10px",
            },
          },
        },
      },

      MuiTextField: {
        styleOverrides: {
          root: {
            color: colors.tFieldGray,
            "& .MuiInputBase-root": {
              minHeight: "38px",
              padding: "0px 10px",
            },
            "& .MuiInputBase-input": {
              minHeight: "38px",
              padding: "0px 10px",
            },
            "& .MuiInputBase-inputMultiline": {
              borderRadius: "6px",
              fontSize: "14px",
              lineHeight: "22.4px",
              fontWeight: "400",
              padding: "10px 10px",
            },
            "& .MuiOutlinedInput-root": {
              minHeight: "38px",
              borderRadius: "6px",
              fontSize: "14px",
              lineHeight: "22.4px",
              fontWeight: "400",
              padding: "0px 10px",
              "& fieldset": {
                borderColor: "#DEDFE4",
                borderRadius: "6px",
                fontSize: "14px",
                lineHeight: "22.4px",
                fontWeight: "400",
                padding: "0px 10px",
              },
              "&:placeholder fieldset": {
                color: "#DEDFE4",
              },
              "&:hover fieldset": {
                border: `2px solid ${colors.main}`,
              },
              "&.Mui-focused fieldset": {
                border: `2px solid ${colors.main}`,
              },
            },
          },
        },
      },
    },
  },
  enUS
);
