import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";

import ContentBox from "../../components/ui/ContentBox";

import { useParams } from "react-router-dom";
import BreadcrumComp from "../../components/ui/BreadcrumComp";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import FormikErrorText from "../../components/ui/FormikErrorText";
import BtaDatePicker from "../../components/ui/BtaDatePicker";
import moment from "moment";
import "./style.scss";
import * as Yup from "yup";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarPicker, PickersDay } from "@mui/x-date-pickers";
import { styled } from "@mui/material/styles";
import startOfDay from "date-fns/startOfDay";

function TermDetails() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const param = useParams();
  const [term, setTerm] = useState();
  const [openDeleteTerm, setOpenDeleteTerm] = useState(false);
  const perPage = "?perPage=10";

  const formRef = useRef();
  const [valuez, setValuez] = useState([]);

  //get term by id
  useEffect(() => {
    document.title = "BTA admin - Term details";
  }, []);
  useEffect(() => {
    Api.get(Urls.GET_TERM + `/${param.id}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((response) => {
        console.log("single term:", response.data.data);
        setTerm(response?.data?.data);
        const initialSelectedDates = response.data.data.holidays.map((dateString) => startOfDay(new Date(dateString))); // convert backend dates to dates that CalendarPicker can take
        setValuez(initialSelectedDates); // set initial calendar state
        if (formRef) {
          formRef.current.setFieldValue("holidays", datesToBackend(term.holidays)); //set the form dates
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, []);

  const initialValues = {
    name: term?.name || "",
    start_date: term?.start_date || "",
    end_date: term?.end_date || "",
    holidays: term?.holidays || "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    start_date: Yup.date().typeError("Date must be valid").required("This field is required"),
    end_date: Yup.date().typeError("Date must be valid").required("This field is required"),
    holidays: Yup.array(),
  });

  const datesToBackend = (dates) => {
    const newDates = [];
    for (const date of dates) {
      newDates.push(moment(date).format("YYYY-MM-DD"));
    }
    console.log("New DATES", newDates);
    return newDates;
  };

  const datesFromBackend = (formattedDates) => {
    const dateObjects = [];

    for (const formattedDate of formattedDates) {
      const date = moment(formattedDate, "YYYY-MM-DD").toDate();
      dateObjects.push(date);
    }

    return dateObjects;
  };

  const onSubmitUpdateTerm = (values) => {
    console.log("values", values);
    Api.patch(
      Urls?.UPDATE_TERM + `/${param.id}`,
      {
        name: values.name,
        start_date: values.start_date,
        end_date: values.end_date,
        holidays: datesToBackend(values.holidays),
      },
      { headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` } }
    )
      .then((response) => {
        console.log(response);
        toast.success(response.data.message);
      })
      .catch((error) => {
        console.log("error", error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  const handleDeleteTerm = () => {
    Api.delete(Urls?.DELETE_TERM + `/${param}`, { headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` } })
      .then((response) => {
        console.log(response);
        setOpenDeleteTerm(false);
        toast.success(response.data.message);
      })
      .catch((error) => {
        console.log("error", error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  //calendar multiselect
  const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== "selected",
  })(({ theme, selected }) => ({
    ...(selected && {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.dark,
      },
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    }),
  }));

  const findDate = (dates, date) => {
    const dateTime = date.getTime();
    return dates.find((item) => item.getTime() === dateTime);
  };

  const findIndexDate = (dates, date) => {
    const dateTime = date.getTime();
    return dates.findIndex((item) => item.getTime() === dateTime);
  };

  const formatDate = (date) => {
    const parsedDate = moment(date, "YYYY-MM-DD HH:mm:ss");
    const formattedDate = parsedDate.format("D MMMM YYYY");
    return formattedDate;
  };

  const renderPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!valuez) {
      return <PickersDay {...pickersDayProps} />;
    }

    const selected = findDate(valuez, date);

    return <CustomPickersDay {...pickersDayProps} disableMargin selected={selected} />;
  };

  function isDateInRange(dateToCheck, startDateStr, endDateStr) {
    const startDate = moment(startDateStr, "YYYY-MM-DD");
    const endDate = moment(endDateStr, "YYYY-MM-DD");
    const dateToCheckMoment = moment(dateToCheck);

    return dateToCheckMoment.isBetween(startDate, endDate, null, "[]"); // '[]' includes both start and end dates
  }

  return (
    <Box display="flex" flexDirection="column" gap="26px" marginTop="26px">
      <ToastContainer />
      <Box>
        <Box>
          <BreadcrumComp first="Terms" second={term?.name} />
        </Box>
      </Box>
      <ContentBox>
        <Box display="flex" flexDirection="column" gap="16px">
          <Typography fontSize="18px" fontWeight="500" lineHeight="30px" color="#4B465C">
            Update term
          </Typography>
          <Box marginTop="8px" display="flex" gap="16px" flexDirection="column" justifyContent="space-between">
            <Box>
              <Formik innerRef={formRef} onSubmit={onSubmitUpdateTerm} validationSchema={validationSchema} initialValues={initialValues} enableReinitialize={true}>
                {({ errors, setFieldValue, values }) => (
                  <Form>
                    <Box display="flex" flexDirection="column" gap="16px">
                      <Box>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Name
                        </Typography>
                        <Box>
                          <Field sx={{ width: { xs: "100%", md: "50%" } }} name="name" type="input" id="name" as={TextField} autoComplete="off" placeholder="Summer season 2024" />
                        </Box>
                        <ErrorMessage component={FormikErrorText} name="name" />
                      </Box>

                      <Box sx={{ width: { xs: "100%", md: "50%" } }}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Start date
                        </Typography>
                        <Field fullWidth width="100%" name="start_date" type="input" id="start_date" component={BtaDatePicker} autoComplete="off" placeholder="Select a date..." />
                        <ErrorMessage component={FormikErrorText} name="start_date" />
                      </Box>
                      <Box sx={{ width: { xs: "100%", md: "50%" } }}>
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          End date
                        </Typography>
                        <Field fullWidth width="100%" name="end_date" type="input" id="end_date" component={BtaDatePicker} autoComplete="off" placeholder="Select a date..." />
                        <ErrorMessage component={FormikErrorText} name="end_date" />
                      </Box>

                      <Box display="flex" gap="10px" flexDirection="column">
                        <Typography fontSize="13px" fontWeight="400" color="#4B465C">
                          Holidays
                        </Typography>
                        <Box display="flex" flexDirection="row" gap="24px">
                          <LocalizationProvider sx={{ margin: "0px", display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }} dateAdapter={AdapterDateFns}>
                            <CalendarPicker
                              className="calendar"
                              displayStaticWrapperAs="desktop"
                              value={valuez}
                              disableMaskedInput
                              onChange={(newValue) => {
                                const array = [...valuez];
                                const date = startOfDay(newValue);
                                const index = findIndexDate(array, date);
                                if (index >= 0) {
                                  array.splice(index, 1);
                                } else {
                                  array.push(date);
                                }
                                setValuez(array);
                                setFieldValue("holidays", array);
                                console.log("NEWVALUE:::::::::", newValue);
                                console.log("VALUEZ  :::::::::", valuez);
                              }}
                              renderDay={renderPickerDay}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                          <ContentBox maxWidth="400px" padding="16px" sx={{ border: "1px solid lightgray", borderRadius: "6px" }}>
                            {valuez.map((item, index) => (
                              <Typography color={isDateInRange(item, term?.start_date, term?.end_date) ? "black" : "red"} key={index}>{`${formatDate(item)}`}</Typography>
                            ))}
                          </ContentBox>
                        </Box>
                      </Box>

                      <Box display="flex" flexDirection="row" gap="10px">
                        <Button variant="primary_active" sx={{ height: "38px" }} type="submit">
                          Update
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </ContentBox>
    </Box>
  );
}

export default TermDetails;
