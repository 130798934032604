export const courts_columns = [
  {
    name: "ID",
    width: "5%",
    align: "left",
  },
  {
    name: "COURT NAME",
    width: "15%",
    align: "left",
  },
  {
    name: "ADDRESS",
    width: "20%",
    align: "left",
  },
  {
    name: "ACTION",
    width: "55%",
    align: "left",
  },
];
