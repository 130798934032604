import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import AdminIcon from "../../imgs/admin-icon.svg";
import userIcon from "../../imgs/user-icon.svg";
import coachIcon from "../../imgs/coach-icon.svg";
function UserType({ variant }) {
  switch (variant) {
    case "admin":
      return (
        <Box display="flex" gap="10px" alignItems="center" justifyContent="center">
          <Avatar variant="circular" sx={{ backgroundColor: "rgba(0, 207, 232, 0.2)", height: "30px", width: "30px" }}>
            <img src={AdminIcon} alt="admin pic" style={{ height: "22px", width: "22px" }} />
          </Avatar>
          <Box>
            <Typography fontWeight="600" fontSize="15px" lineHeight="22px" color="#4B465C">
              Admin
            </Typography>
          </Box>
        </Box>
      );
    case "client":
      return (
        <Box display="flex" gap="10px" alignItems="center" justifyContent="center">
          <Avatar variant="circular" sx={{ backgroundColor: "rgba(234, 84, 85, 0.2)", height: "30px", width: "30px" }}>
            <img src={userIcon} alt="user pic" style={{ height: "22px", width: "22px" }} />
          </Avatar>
          <Box>
            <Typography fontWeight="600" fontSize="15px" lineHeight="22px" color="#4B465C">
              Client
            </Typography>
          </Box>
        </Box>
      );
    case "coach":
      return (
        <Box display="flex" gap="10px" alignItems="center" justifyContent="center">
          <Avatar variant="circular" sx={{ backgroundColor: "rgba(255, 159, 67, 0.2)", height: "30px", width: "30px" }}>
            <img src={coachIcon} alt="coach pic" style={{ height: "22px", width: "22px" }} />
          </Avatar>
          <Box>
            <Typography fontWeight="600" fontSize="15px" lineHeight="22px" color="#4B465C">
              Coach
            </Typography>
          </Box>
        </Box>
      );
  }
}

export default UserType;
