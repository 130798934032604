import React from "react";
import { Button } from "@mui/material";

function NavbarButton({ children, icon, ...props }) {
  return (
    <Button variant="navbar" {...props} startIcon={icon}>
      {children}
    </Button>
  );
}

export default NavbarButton;
