import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { colors } from "../../../theme/Colors";
import { usePointerDifference } from "./utils";

const TRACK_START = 0;
const TRACK_END = 14;
const KNOB_SWIPE_DELTA = 10;

const Track = styled.div((props) => [
  {
    boxSizing: "border-box",
    position: "relative",
    width: "32px",
    height: "18px",
    borderRadius: 31,
    borderStyle: "solid",
    borderWidth: 2,
    userSelect: "none",
    transition: "200ms ease-out",
    cursor: "pointer",
    WebkitTapHighlightColor: "rgba(0,0,0,0)",
    "&:focus:not(:focus-visible)": {
      outline: "none",
    },
    "&:active": {
      cursor: "pointer",
    },
  },
  props.isOn
    ? {
        borderColor: `${colors.main}`,
        backgroundColor: `${colors.main}`,
      }
    : {
        borderColor: "#E5E5E5",
        backgroundColor: "#DEDFE4",
        "&:active": {
          boxShadow: "0px 0px 0px 14px inset #E5E5E5",
        },
      },
]);

const Knob = styled.div((props) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: 14,
  height: 14,
  transform: `scaleX(1) translateX(${props.isOn ? TRACK_END : TRACK_START}px)`,
  transformOrigin: props.isOn ? 48 : 0,
  transition: "200ms ease-out",
  backgroundColor: "white",
  borderRadius: "100%",
  boxShadow: `
    0px 3px 8px 0px rgba(0, 0, 0, 0.15),
    0px 3px 1px 0px rgba(0, 0, 0, 0.06)
  `,
  "&:active": {
    transform: `scaleX(1.15) translateX(${props.isOn ? TRACK_END : TRACK_START}px)`,
  },
}));

function MySwitch({ isOn, onChange, onClick }) {
  const [bind, difference] = usePointerDifference();
  if (isOn) {
    if (difference < -KNOB_SWIPE_DELTA) {
      onChange(false);
    }
  } else {
    if (difference > KNOB_SWIPE_DELTA) {
      onChange(true);
    }
  }
  return (
    <Track role="checkbox" tabIndex="0" aria-checked={isOn} isOn={isOn} onClick={() => onChange(!isOn)} onKeyDown={(event) => event.key === " " && onChange(!isOn)} {...bind}>
      <Knob isOn={isOn} />
    </Track>
  );
}

export default MySwitch;
