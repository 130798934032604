import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

function TableShowCategory({ url_photo, category_name }) {
  return (
    <Box display="flex" gap="10px" alignItems="center">
      <Avatar variant="rounded" sx={{ height: "50px", width: "50px" }}>
        {url_photo ? <img src={url_photo} alt="" height="50px" width="50px" /> : <PersonIcon sx={{ height: "30px", width: "30px" }} />}
      </Avatar>
      <Box>
        <Typography fontWeight="600" fontSize="15px" lineHeight="22px" color="#4B465C" textTransform="uppercase">
          {category_name}
        </Typography>
      </Box>
    </Box>
  );
}

export default TableShowCategory;
