import React from "react";
import Navbar from "../components/ui/navbar/Navbar";
import { Outlet } from "react-router-dom";
import { Container, Box } from "@mui/material";

function SharedLayout({ userData }) {
  console.log(userData, "userData shared layout");
  return (
    <Box>
      <Navbar userData={userData} />
      <Container maxWidth={"xl"} sx={{ minHeight: "80vh", display: { xs: "column" }, marginBottom: "50px" }}>
        {/* OUTLET ==> contains content pages  */}
        <Box>
          <Outlet />
        </Box>
      </Container>
    </Box>
  );
}

export default SharedLayout;
