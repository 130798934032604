import "./App.css";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import SharedLayout from "./pages/SharedLayout";
import Test from "./Test";
import Login from "./pages/authentification/Login";
import AdminProfile from "./pages/AdminProfile";
import PlayerDetails from "./pages/players/PlayerDetails";
import Players from "./pages/players/Players";
import Users from "./pages/users/users-list/Users";
import Terms from "./pages/terms/Terms";
import PrivateLessons from "./pages/private-lessons/PrivateLessons";
import { useAuthContext } from "./hooks/useAuthContext";
import CoachDetails from "./pages/users/user-coach/CoachDetails";
import UserSelection from "./pages/users/user-selection/UserSelection";
import TermsDetails from "./pages/terms/TermDetails";
import Courts from "./pages/courts/Courts";
import CourtsDetails from "./pages/courts/CourtsDetails";
import { useEffect, useState } from "react";
import Api from "./api/Api";
import { Urls } from "./api/Urls";
import Coaches from "./pages/coaches/Coaches";
import Programs from "./pages/programs/Programs";
import Dashboard from "./pages/dashborad/Dashboard";
import Levels from "./pages/levels/Levels";
import Schedule from "./pages/schedule/Schedule";
import Purchases from "./pages/store/purchases/Purchases";
import PurchaseDetails from "./pages/store/purchases/PurchaseDetails";
import WeeklySchedule from "./pages/schedule/WeeklySchedule";
import Categories from "./pages/store/categories/Categories";
import Products from "./pages/store/products/Products";
import CreateProduct from "./pages/store/products/CreateProduct";
import UpdateProduct from "./pages/store/products/UpdateProduct";
import Settings from "./pages/settings/Settings";
import PlayersSchedule from "./pages/players/PlayersSchedule";

function App() {
  const { user } = useAuthContext();
  console.log("user is :", user);

  const user_id = user?.data?.id;
  const access_token = user?.access_token;
  const [userData, setUserData] = useState({});
  const [updateInfos, setUpdateInfos] = useState(false);
  const [isAdminUpdated, setIsAdminUpdated] = useState(false);
  useEffect(() => {
    if (user) {
      Api.get(Urls?.GET_USER + user_id, {
        headers: { "content-type": "application", authorization: `Bearer ${access_token}` },
      })
        .then((res) => {
          setUserData(res?.data?.data);
          console.log(res?.data?.data, "update infos app");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [updateInfos, user, isAdminUpdated]);
  return (
    <>
      <Routes>
        <Route path="" element={<Navigate to="login" />} />
        <Route path="/" element={<Outlet />}>
          <Route path="login" element={user ? <Navigate to="/admin/dashboard" replace /> : <Login />} />
          <Route path="/admin" element={user ? <SharedLayout userData={userData} /> : <Login />}>
            <Route path="profile" element={<AdminProfile updateInfos={updateInfos} setUpdateInfos={setUpdateInfos} userData={userData} />} />
            <Route path="dashboard" element={<Dashboard />} />
            {/* players */}
            <Route path="players">
              <Route path="/admin/players" index element={<Players />} />
              <Route path="/admin/players/schedule" index element={<PlayersSchedule />} />
              <Route path="/admin/players/:id" element={<PlayerDetails />} />
            </Route>
            {/* users */}
            <Route path="users">
              <Route index element={<Users />} />
              <Route path="/admin/users/:id" element={<UserSelection isAdminUpdated={isAdminUpdated} setIsAdminUpdated={setIsAdminUpdated} />} />
            </Route>
            {/* terms */}
            <Route path="terms">
              <Route index element={<Terms />} />
              <Route path="/admin/terms/:id" element={<TermsDetails />} />
            </Route>
            {/* courts */}
            <Route path="courts">
              <Route index element={<Courts />} />
              <Route path="/admin/courts/:id" element={<CourtsDetails />} />
            </Route>
            <Route path="coaches" element={<Coaches />} />
            <Route path="levels" element={<Levels />} />
            <Route path="programs">
              <Route index element={<Programs />} />
              <Route path="/admin/programs/private-sessions" element={<PrivateLessons />} />
            </Route>
            <Route path="schedules">
              <Route index element={<Schedule />} />
              <Route path="/admin/schedules/weekly-schedule" element={<WeeklySchedule />} />
            </Route>
            <Route path="store">
              <Route index element={<Products />} />
              <Route path="/admin/store/products">
                <Route index element={<Products />} />
                <Route path="/admin/store/products/add" element={<CreateProduct />} />
                <Route path="/admin/store/products/update/:id" element={<UpdateProduct />} />
              </Route>
              <Route path="/admin/store/categories" element={<Categories />} />
            </Route>
            <Route path="purchases">
              <Route index element={<Purchases />} />
              <Route path="/admin/purchases/:id" element={<PurchaseDetails />} />
            </Route>
            <Route path="settings" element={<Settings />} />
            <Route path="tests" element={<Test />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
